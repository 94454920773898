import React, { useContext, useEffect, useRef, useState } from "react";
import { formatISO8601Date } from "../../helpers/timeHelper";
import { FIREBASE_AUTH_HERMES } from "../../FirebaseConfig";
import { Link, useNavigate } from "react-router-dom";
import { environment_, START_FETCHING_HEIGHT } from "../../helpers/constants";
import Spinner from "../../components/Spinner";
import { UserContext } from "../../context/UserProvider";
import { ImEye } from "react-icons/im";
import { isFiveMinutesOld } from "../../helpers/helper";

const DEMO_DATA = [
  {
    id: 1,
    userEmail: "test1@test.com",
    submittedDatetime: "2024-05-05",
  },
  {
    id: 2,
    userEmail: "test2@test.com",
    submittedDatetime: "2024-05-06",
    isComplain: true,
    numberOfTimesRejected: 3,
  },
  {
    id: 3,
    userEmail: "test3@test.com",
    submittedDatetime: "2024-05-08",
  },
  {
    id: 4,
    userEmail: "test4@test.com",
    submittedDatetime: "2024-05-09",
  },
  {
    id: 5,
    userEmail: "test5@test.com",
    submittedDatetime: "2024-05-10",
  },
];

const DashboardItem = (props) => {
  const navigate = useNavigate();

  return (
    <>
      {isFiveMinutesOld(props.lastUpdatedDatetime, props.submittedDatetime) && (
        <div
          class="w-[75%] p-[1em] flex flex-row justify-between  border-x border-b border-[#FF840D]"
          style={{
            backgroundColor: props.index % 2 == 0 ? "white" : "#e6e6e6",
            borderTopWidth: props.index == 0 ? 1 : 0,
          }}
        >
          <div class="flex flex-col ">
            <h2 class="text-lg underline">Request ID: {props.id} </h2>
            <h2 class="text-xl">
              User Email: <b>{props.userEmail}</b>
            </h2>
            <h2 class="text-md text-gray-400">
              {formatISO8601Date(props.submittedDatetime)}{" "}
            </h2>
          </div>
          <div class="flex flex-row">
            {/* {!isFiveMinutesOld(props.lastUpdatedDatetime) && (
          <div class="justify-center flex flex-col items-center mr-2">
            <ImEye></ImEye>
            <h2>Being Reviewed</h2>
          </div>
        )} */}
            <button
              onClick={() => {
                navigate(`/hermes/${props.id}`, { state: { props } });
              }}
              class="rounded-lg border border-[#140901] shadow-xl text-white  hover:text-[#140901]	 text-2xl py-[0.5em] bg-[#FF840D]  w-fit px-[1em]   hover:bg-[#FFDBB1] transition "
            >
              <h2 class="text-3xl font-bold ">REVIEW</h2>
            </button>
          </div>
        </div>
      )}
    </>
  );
};
const HermesDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [activities, setActivities] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [fetchedAtLeastOnce, setFetchedAtLeastOnce] = useState(false);
  const { user } = useContext(UserContext);
  const containerRef = useRef(null);

  const getData = async () => {
    setIsLoading(true);
    await fetch(
      process.env.REACT_APP_API_URL +
        environment_ +
        `/receipt_activity?last_evaluated_key=${encodeURIComponent(
          JSON.stringify(lastEvaluatedKey)
        )}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          console.log("Error in getting receipt activities");
          console.log(await response.json());
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(async (data) => {
        setActivities([...activities, ...data["items"]]);
        setLastEvaluatedKey(data["last_evaluated_key"]);
      })
      .catch((error) => {
        console.error(
          "There was a problem with the fetch operation with getting receipt activities:",
          error
        );
      })
      .finally(() => {
        setIsLoading(false);
        setFetchedAtLeastOnce(true);
      });
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    if (!fetchedAtLeastOnce) getData();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastEvaluatedKey, fetchedAtLeastOnce, isLoading]);
  function canFetch() {
    // if lastEvalutedKey is null, we know there's no more page to fetch
    // But we also need to know if we at least fetched it once, since
    // lastEvaluatedKey is initialized as null
    return !(lastEvaluatedKey == null && fetchedAtLeastOnce);
  }

  const handleScroll = async (event) => {
    if (!canFetch() || isLoading) return;
    const windowHeight = window.innerHeight;
    const fullHeight = document.documentElement.scrollHeight;
    const currentPosition = window.scrollY;

    const scrollPercentage = currentPosition / (fullHeight - windowHeight);

    // Fetch more data if scrolled to 80% from the top and there are more pages to fetch
    if (scrollPercentage >= START_FETCHING_HEIGHT && canFetch() && !isLoading) {
      await getData();
    }
  };

  return (
    <div class="flex flex-col justify-center items-center min-h-screen p-16">
      {isLoading && !fetchedAtLeastOnce ? (
        <Spinner />
      ) : (
        <>
          <div class="w-[75%] pb-[1em]">
            <h2 class="text-3xl">Requests: </h2>
          </div>

          {activities.map((item, index) => (
            <DashboardItem {...item} index={index} key={index}></DashboardItem>
          ))}
          {isLoading && <Spinner />}
          <h2 class="text-gray-400">{activities.length} items</h2>
        </>
      )}

      <button
        onClick={() => {
          console.log("Signing out");
          FIREBASE_AUTH_HERMES.signOut();
        }}
        class="absolute top-12 right-12 bg-white p-[0.5em] border border-black rounded"
      >
        <h2>Sign out</h2>
      </button>
    </div>
  );
};

export default HermesDashboard;
