import React from "react";
import { AiOutlineGlobal } from "react-icons/ai";
import { FaTag, FaHandshake } from "react-icons/fa";
import { GiPayMoney } from "react-icons/gi";
import { IoReceiptOutline } from "react-icons/io5";

const SectionOne = () => {
  return (
    <div className="w-[100%] px-[10vw] py-[7em]  h-fit  bg-[#FFDF9B]">
      <div className="justify-center items-center h-[10%] text-center">
        <h2 className="mb-16 text-5xl font-bold color-[#FF914D] ">
          Why Campfire?
        </h2>
      </div>
      <div className="justify-between flex md-max:flex-col flex-row items-center text-center h-fit">
        <div className="md-max:mb-16 justify-center items-center flex flex-col md-max:w-[80%]  w-[30%]">
          <div className="shadow-xl flex justify-center items-center mb-8 text-center bg-[#FF914D] rounded-full h-[172px] w-[172px]">
            <AiOutlineGlobal size={112} color="#FEFFFF" />
          </div>
          <h4 className="text-xl font-bold">Unified Point System</h4>
          <h5 className="text-xl">
            Share loyalty points with other businesses in the system. Win-win
            for you and businesses around you.
          </h5>
        </div>
        <div className="md-max:mb-16 justify-center items-center flex flex-col md-max:w-[80%] w-[30%]">
          <div className="shadow-xl flex justify-center items-center mb-8 text-center bg-[#FF914D] rounded-full h-[172px] w-[172px]">
            <GiPayMoney size={112} color="#FEFFFF" />
          </div>
          <h4 className="text-xl font-bold">Increased Incentives</h4>
          <h5 className="text-xl">
            Your customers can now collect points more easily than ever before.
            As a result, they are more likely to spend money at your business
            because of the loyalty program.
          </h5>
        </div>
        <div className="md-max:mb-16 justify-center items-center flex flex-col md-max:w-[80%] w-[30%]">
          <div className="shadow-xl flex justify-center items-center mb-8 text-center bg-[#FF914D] rounded-full h-[172px] w-[172px]">
            <IoReceiptOutline size={100} color="#FEFFFF" />
          </div>
          <h4 className="text-xl font-bold">Receipt System</h4>
          <h5 className="text-xl">
            Your customer can upload any receipts from your platforms and
            receive points. Any receipts work - in-person purchase, online
            delivery, or even from your own eCommerce website.
          </h5>
        </div>
      </div>

      <div className="relative justify-between  flex flex-col items-center text-center h-fit">
        <div className="flex flex-1	items-center w-[100%] md-max:flex-col flex-row  justify-center  ">
          <img
            alt="campfire_with_people"
            src="images/stat.png"
            className="h-[600px] object-contain pr-4 md-max:pr-0"
          ></img>
          <img
            alt="campfire_with_people"
            src="images/coupon_stat.png"
            className="h-[600px] object-contain pl-4 md-max:pl-0"
          ></img>
        </div>
        <div className="flex mt-5">
          <h4 className="text-xl">
            You also get access to in-app statistics, see how your business and
            coupons are doing in real-time.
          </h4>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
