import React, { useState } from "react";
import "../App.css";
import useWindowDimensions from "../hook/useWindowDimensions";
import { IoMenu } from "react-icons/io5";
import { DOWNLOAD_LINK, DOWNLOAD_LINK_FAST } from "../helpers/constants";
import { useNavigate } from "react-router-dom";

const BURGER_WIDTH = 1460;

const mountedStyle = { animation: "inAnimation 250ms ease-in" };
const unmountedStyle = {
  animation: "outAnimation 270ms ease-out",
  animationFillMode: "forwards",
};

const NavBarMoco = ({ howItWorksRef, calendlyRef, FAQsRef }) => {
  const { height, width } = useWindowDimensions();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const scrollToCalendly = () => {
    setOpen(false);
    if (calendlyRef && calendlyRef.current) {
      calendlyRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const scrollToHowItWorks = () => {
    setOpen(false);
    if (howItWorksRef && howItWorksRef.current) {
      howItWorksRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleAnimationEnd = () => {
    if (!open) {
      setOpen(false); // Hide the element after animation ends
    }
  };

  const scrollToFAQs = () => {
    // Replace 'targetSection' with the reference to the element you want to scroll to
    setOpen(false);
    if (FAQsRef && FAQsRef.current) {
      FAQsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  // Create a Ref for the target section you want to scroll to

  return (
    <div className="navStyle p-[1em] px-[10vw] h-[7.5vh] sm:h-[10vh] md:h-[12.5vh]  border-b-2 ">
      <a
        href="/"
        className=" h-[100%] flex-col flex justify-center items-center "
      >
        <img
          src="/images/logo_trans.png"
          alt="logo_text.png"
          className="logo-style w-[64px] "
        ></img>
      </a>

      {width <= BURGER_WIDTH ? (
        <>
          <div className="w-full flex flex-row-reverse mr-[2em]  ">
            <div
              className={`burger ${
                open ? "open" : ""
              } w-[25px] h-[16px] sm:w-[30px] sm:h-[20px]`}
              onClick={() => setOpen((val) => !val)}
            >
              <div className="burger-line"></div>
              <div className="burger-line"></div>
              <div className="burger-line"></div>
            </div>
          </div>
          <ul
            className="navbar-ul-mobile "
            style={open ? mountedStyle : unmountedStyle}
            onAnimationEnd={handleAnimationEnd}
          >
            <a href="/MoCo">
              <li className="navbar-li-mobile">MoCoPass</li>
            </a>
            {calendlyRef && (
              <a href="#joinus" onClick={scrollToCalendly}>
                <li className="navbar-li-mobile">Join Us</li>
              </a>
            )}
            {howItWorksRef && (
              <a href="#howitworks" onClick={scrollToHowItWorks}>
                <li className="navbar-li-mobile">How it works</li>
              </a>
            )}
            {FAQsRef && (
              <a href="#FAQs" onClick={scrollToFAQs}>
                <li className="navbar-li-mobile">FAQs</li>
              </a>
            )}
            <a href="/MoCo/offers">
              <li className="navbar-li-mobile">See All Offers</li>
            </a>
            {/* <a href="/about">
              <li className="navbar-li-mobile">About</li>
            </a> */}
          </ul>
        </>
      ) : (
        <ul className="navbar-ul">
          <a href="/MoCo/offers">
            <li className="navbar-li">See All Offers</li>
          </a>
          {FAQsRef && (
            <a href="#FAQs" onClick={scrollToFAQs}>
              <li className="navbar-li">FAQs</li>
            </a>
          )}
          {howItWorksRef && (
            <a href="#howitworks" onClick={scrollToHowItWorks}>
              <li className="navbar-li">How it works</li>
            </a>
          )}
          {calendlyRef && (
            <a href="#joinus" onClick={scrollToCalendly}>
              <li className="navbar-li">Join Us</li>
            </a>
          )}
          <a href="/MoCo">
            <li className="navbar-li">MoCoPass</li>
          </a>
        </ul>
      )}
      <a
        href={DOWNLOAD_LINK_FAST}
        className=" border bg-[#FEFFFF]  hover:bg-[#FF914D] hover:text-[#FEFFFF] border-2	border-[#FF914D] whitespace-nowrap	  rounded-full	text-xl sm:text-2xl lg:text-3xl   text-nowrap	  py-1 md:py-2 px-[1em]  text-[#FF914D]  transition shadow-md	"
      >
        Download
      </a>
    </div>
  );
};

export default NavBarMoco;
