import React from "react";
import Waitlist from "../components/Waitlist";

const WaitlistPage = () => {
  return (
    <div>
      <Waitlist></Waitlist>
    </div>
  );
};

export default WaitlistPage;
