import React from "react";
import useWindowDimensions from "../hook/useWindowDimensions";
import { DOWNLOAD_LINK } from "../helpers/constants";

const Banner = ({ signUpRef, waitlistRef }) => {
  const scrollToSignUp = () => {
    // Replace 'targetSection' with the reference to the element you want to scroll to
    if (signUpRef && signUpRef.current) {
      signUpRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const scrollToWaitlist = () => {
    // Replace 'targetSection' with the reference to the element you want to scroll to
    if (waitlistRef && waitlistRef.current) {
      waitlistRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const { height, width } = useWindowDimensions();

  return (
    <div className="mt-[7.5vh] sm:mt-[10vh] md:mt-[12.5vh] px-[10vw] bannerStyle flex flex-col xl:flex-row justify-start p-8 h-[60vh] justify-between ">
      <div className="flex justify-center xl:justify-start items-center    xl:items-start overflow-wrap flex-col  flex-1">
        <h1 className=" md-max:text-[50px]  text-[60px] sm:text-[64px] md:text-[76px] xl:text-[84px] 2xl:text-[96px]  leading-tight text-center xl:text-left  ">
          One
          {width >= 1280 ? <br /> : " "}
          Loyalty
          {width >= 1280 ? <br /> : " "}
          Program,
          {width >= 1280 ? <br /> : " "}
          Multiple Stores
        </h1>
        {width >= 1280 && (
          <div className="flex justify-start  ">
            <button
              onClick={() => {
                window.location.href = DOWNLOAD_LINK;
              }}
              className=" border bg-[#FEFFFF] whitespace-nowrap border-2	border-[#FF914D]  rounded-full hover:bg-[#FF914D] hover:text-[#FEFFFF]	md-max:text-xl text-[36px] py-2 px-6 mr-4 mt-[1em] mb-[1em]  text-[#FF914D]  transition shadow-md	"
            >
              Download
            </button>
            <button
              onClick={scrollToSignUp}
              className=" border bg-[#FEFFFF] whitespace-nowrap  border-2	border-[#FF914D]  rounded-full hover:bg-[#FF914D] hover:text-[#FEFFFF]	md-max:text-xl text-[36px] py-2 px-6 mt-[1em] mb-[1em] text-[#FF914D]  transition shadow-md	"
            >
              Sign up
            </button>
          </div>
        )}
      </div>
      <div className="justify-center flex-1 items-center flex flex-col mt-[2em] xl:mt-[0em] ">
        <img
          alt="campfire_with_people"
          src="images/onesystem.png"
          className="w-[100%] object-contain"
        ></img>
        {width < 1280 && (
          <div className="flex justify-start  mt-[2em] ">
            <button
              onClick={() => {
                window.location.href = DOWNLOAD_LINK;
              }}
              className=" border bg-[#FEFFFF] whitespace-nowrap border-2	border-[#FF914D]  rounded-full hover:bg-[#FF914D] hover:text-[#FEFFFF]	md-max:text-xl text-[36px] py-2 px-6 mr-4 mt-[1em] mb-[1em]  text-[#FF914D]  transition shadow-md	"
            >
              Download
            </button>
            <button
              onClick={scrollToSignUp}
              className=" border bg-[#FEFFFF] whitespace-nowrap  border-2	border-[#FF914D]  rounded-full hover:bg-[#FF914D] hover:text-[#FEFFFF]	md-max:text-xl text-[36px] py-2 px-6 mt-[1em] mb-[1em] text-[#FF914D]  transition shadow-md	"
            >
              Sign up
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Banner;
