import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { capitalizeWords } from "../../../helpers/helper";
import { useDropzone } from "react-dropzone";
import {
  CATEGORIES,
  DIETARY_RESTRICTIONS,
  environment_,
} from "../../../helpers/constants";
import { RxCross2 } from "react-icons/rx";
import Modal from "../../../components/Modal";
import imageCompression from "browser-image-compression";
import LoadingIcon from "../../../components/LoadingIcon";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserProvider";
/**
 * Non-admin people only have the ability to add a business
 * Ability to remove or edit a business is not given
 */
const HermesAddBusinessAndUser = () => {
  const { user } = useContext(UserContext);

  const [preview, setPreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [DR, setDR] = useState(DIETARY_RESTRICTIONS);
  const [categories, setCategories] = useState(CATEGORIES);
  const [otherCategory, setOtherCategory] = useState("");
  const [keywords, setKeywords] = useState([""]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    base64_img: "",
    business_owner_email: "",
    dummy_password: "",
    is_moco_business: 0,
    is_campfire_business: 0,
    about: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    phone_number: "",
    email_address: "",
    lng: "",
    lat: "",
    name: "",
    categories: [],
  });
  const wheelRef = useRef();
  const isCampfireBusinessRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [showModalAfter, setShowModalAfter] = useState(false);
  const [modalMetadata, setModalMetadata] = useState({
    text: "",
    redirect: false,
  });
  const navigate = useNavigate();

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModalAfter = () => {
    setShowModalAfter(true);
  };

  const handleCloseModalAfter = (redirectOnClick) => {
    setShowModalAfter(false);
    console.log("modalMetadata", modalMetadata);
    if (redirectOnClick) navigate("/hermes/business");
  };

  const handleAddKeyword = () => {
    if (keywords.length < 5) {
      setKeywords([...keywords, ""]);
    }
  };

  const handleKeywordChange = (index, value) => {
    const newKeywords = [...keywords];
    newKeywords[index] = value;
    setKeywords(newKeywords);
  };

  const handleRemoveKeyword = (index) => {
    const newKeywords = keywords.filter((_, i) => i !== index);
    setKeywords(newKeywords);
  };

  // Concatenate categories, DR, and keywords into one list. Remove empty keywords
  const getTags = () => {
    const checkedCategories = categories
      .filter((item) => item.checked)
      .map((item) => item.text);
    const checkedDR = DR.filter((item) => item.checked).map(
      (item) => item.text
    );
    const checkedKeywords = keywords.filter((item) => item.length > 0);

    return checkedCategories.concat(checkedDR.concat(checkedKeywords));
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (file) {
      try {
        // Compress and convert to WebP
        const options = {
          maxSizeMB: 1, // Max size in MB
          maxWidthOrHeight: 800, // Max width or height
          useWebWorker: true,
          fileType: "image/webp", // Convert to WebP
        };

        const compressedFile = await imageCompression(file, options);
        const compressedDataURL = await imageCompression.getDataUrlFromFile(
          compressedFile
        );
        console.log("compressedDataURL", compressedDataURL);
        setPreview(compressedDataURL);
      } catch (error) {
        console.error("Image compression error:", error);
      }
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/webp": [],
    },
    multiple: false,
  });

  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormChangeCheck = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked ? "1" : "0",
    });
  };

  const handleChangeDR = (id) => {
    setDR(
      DR.map((dr) => (dr.id === id ? { ...dr, checked: !dr.checked } : dr))
    );
  };

  const handleChangeCategory = (id) => {
    setCategories(
      categories.map((category) =>
        category.id === id
          ? { ...category, checked: !category.checked }
          : category
      )
    );
  };

  useEffect(() => {
    document.title = "Add a Business";
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    if (
      formData["is_campfire_business"] == 0 &&
      formData["is_moco_business"] == 0
    ) {
      alert(
        "Please make sure the business is at least a Campfire Business, a MoCo Business, or both."
      );
      isCampfireBusinessRef.current.scrollIntoView({ behavior: "smooth" });
      return;
    }
    const data = {
      ...formData,
      base64_img: preview,
      categories: getTags(),
    };
    console.log("data is", data);
    setIsLoading(true);
    await fetch(
      process.env.REACT_APP_HERMES_API +
        environment_ +
        "/add_business_and_user",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
        body: JSON.stringify(data),
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          const res = await response.json();
          console.log(res);
          // setModalText(res["error"]);
          setModalMetadata({
            text: res["error"],
            redirect: false,
          });
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((returnedData) => {
        // setModalText(
        //   `Business added successfully!\n\nStore these credentials and pass them to the business owners\nEmail: ${data["business_owner_email"]}\nPassword: ${data["dummy_password"]}`
        // );

        setModalMetadata({
          text: `Business added successfully!\n\nStore these credentials and pass them to the business owners\nEmail: ${data[
            "business_owner_email"
          ].toLowerCase()}\nPassword: ${data["dummy_password"]}`,
          redirect: true,
        });
        console.log("Response is ", returnedData);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      })
      .finally(() => {
        setShowModalAfter(true);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    const handleWheel = (e) => {
      // Prevent the default scroll behavior
      console.log("asdas dosamodj");
      if (document.activeElement === wheelRef.current) {
        e.preventDefault();
      }
    };

    // Add event listener for wheel and touchpad scrolling
    wheelRef.current.addEventListener("wheel", handleWheel);

    // Cleanup event listener on component unmount
    return () => {
      wheelRef.current.removeEventListener("wheel", handleWheel);
    };
  }, []);
  return (
    <div className="flex flex-col justify-center items-center min-h-screen p-16 bg-orange-100">
      <div className="flex flex-col justify-center shadow-xl rounded-xl h-full py-4 px-8 mb-8 max-w-[35%] bg-white">
        <h2 className="text-xl font-bold mb-4 underline	">Add a Business</h2>
        <h3 className="text-md">
          This form creates a business and sets it as live on the app. It also
          creates an account for the business owner to sign into.
          <b>
            {" "}
            Make sure you note down the login credentials (email and temporary
            password) before submitting this form.
          </b>{" "}
        </h3>
        <br />
        <br />
        <h3 className="text-md">
          Note: You can only add a business, you cannot delete or edit a
          business after you have submitted this form. To delete or edit a
          business, message Bernard or Jordan.
        </h3>
      </div>
      <form
        onSubmit={handleSubmit}
        className="justify-center  flex flex-col w-full  items-center"
      >
        <div className="flex flex-col justify-center shadow-xl rounded-xl h-full py-4 px-8 w-[35%] bg-white mb-8">
          <h2 className="text-lg underline font-bold">Business Information</h2>
          <h3 className="text-md">
            All this information will be displayed on the app.
          </h3>
          <label className="required-field pt-4" for="name">
            Business Name
          </label>
          <input
            onChange={handleFormChange}
            className="max-w-[60%] outline-none	border-b-2 focus:border-orange-400 transition mb-2"
            placeholder="Type Business Name here"
            type="text"
            name="name"
            id="name"
            required
          />

          <label className="required-field pt-4" for="about">
            About
          </label>
          <textarea
            onChange={handleFormChange}
            className="max-w-[80%] outline-none	border-2 p-1 rounded focus:border-orange-400 transition mb-2"
            placeholder="Type about here"
            type="text"
            name="about"
            id="about"
            required
          />
          <label className="required-field pt-4" for="address">
            Address
          </label>
          <input
            onChange={handleFormChange}
            className="max-w-[60%] outline-none	border-b-2 focus:border-orange-400 transition mb-2"
            placeholder="Type address here"
            type="text"
            name="address"
            id="address"
            required
          />
          <label className="required-field pt-4" for="city">
            City
          </label>
          <input
            onChange={handleFormChange}
            className="max-w-[60%] outline-none	border-b-2 focus:border-orange-400 transition mb-2"
            placeholder="Type city here"
            type="text"
            name="city"
            id="city"
            required
          />
          <label className="required-field pt-4" for="state">
            State
          </label>
          <input
            onChange={handleFormChange}
            className="max-w-[60%] outline-none	border-b-2 focus:border-orange-400 transition mb-2"
            placeholder="Type state here"
            type="text"
            name="state"
            id="state"
            required
          />
          <label className="required-field pt-4" for="zipcode">
            Zipcode
          </label>
          <input
            onChange={handleFormChange}
            className="max-w-[60%] outline-none	border-b-2 focus:border-orange-400 transition mb-2"
            placeholder="Type zipcode here"
            type="text"
            name="zipcode"
            id="zipcode"
            required
          />
          <label className="pt-4" for="business_image">
            Business Logo
          </label>
          <h3 className="text-sm">Maximum size: 1MB</h3>
          {preview ? (
            <div className="relative w-fit my-4">
              <img src={preview} alt="Preview" style={{ width: "200px" }} />
              <button
                onClick={() => {
                  setPreview(null);
                }}
                className="absolute top-0 right-0 "
              >
                <RxCross2 size={30} color={"red"} />
              </button>
            </div>
          ) : (
            <div
              name="business_image"
              {...getRootProps()}
              className="cursor-pointer"
              style={{
                border: "2px dashed #ccc",
                padding: "20px",
                textAlign: "center",
              }}
            >
              <input {...getInputProps()} />
              <p>Drag & drop an image here, or click to select one</p>
            </div>
          )}

          <label className="required-field pt-4" for="lat">
            Latitude
          </label>
          <div className=" cursor-pointer pb-4" onClick={handleOpenModal}>
            <h2 className="underline text-sm">
              Click here to learn how to obtain a business's latitude and
              longitude
            </h2>
          </div>
          <input
            ref={wheelRef}
            onChange={handleFormChange}
            className="max-w-[60%] outline-none	border-b-2 focus:border-orange-400 transition mb-2"
            placeholder="Type latitude here"
            type="number"
            step="any"
            name="lat"
            id="lat"
            required
          />
          <label className="required-field pt-4" for="lng">
            Longitude
          </label>

          <div className=" cursor-pointer pb-4" onClick={handleOpenModal}>
            <h2 className="underline text-sm">
              Click here to learn how to obtain a business's latitude and
              longitude
            </h2>
          </div>
          <input
            ref={wheelRef}
            onChange={handleFormChange}
            className="max-w-[60%] outline-none	border-b-2 focus:border-orange-400 transition mb-2"
            placeholder="Type longitude here"
            type="number"
            step="any"
            name="lng"
            id="lng"
            required
          />
          <label
            ref={isCampfireBusinessRef}
            className="required-field pt-4 "
            for="is_campfire_business"
          >
            Is this a Campfire Business?{" "}
            <input
              name="is_campfire_business"
              id="is_campfire_business"
              onChange={handleFormChangeCheck}
              className="custom-checkbox text-orange-500 bg-orange-100 outline-none"
              type="checkbox"
            />
          </label>
          <h3 className="text-sm">
            If this business is joining Campfire, tick the box. This can be
            changed later if the business owner doesn't want to join Campfire
            now.
          </h3>
          <label className="required-field pt-4 " for="is_moco_business">
            Is this a MoCoPass Business?{" "}
            <input
              name="is_moco_business"
              id="is_moco_business"
              onChange={handleFormChangeCheck}
              className="custom-checkbox text-orange-500 bg-orange-100 outline-none"
              type="checkbox"
            />
          </label>
          <h3 className="text-sm">
            If this business is joining MoCoPass, tick the box. This can be
            changed later if the business owner doesn't want to join MoCoPass
            now.
          </h3>
        </div>

        <div className="flex flex-col justify-center shadow-xl rounded-xl h-full py-4 px-8 w-[35%] bg-white mb-8">
          <h2 className="text-lg underline font-bold">
            Business Owner's Contact
          </h2>
          <h3 className="text-sm">
            This information will be used for internal records only, optional
            but useful to have.
          </h3>
          <label className="pt-4" for="phone_number">
            Phone Number
          </label>
          <h3 className="text-sm">Example: 7341234699</h3>
          <input
            onChange={handleFormChange}
            className="max-w-[60%] outline-none	border-b-2 focus:border-orange-400 transition mb-2"
            placeholder="Type phone number here"
            type="tel"
            name="phone_number"
            id="phone_number"
            pattern="[0-9]{10}"
          />
          <label className="pt-4" for="email_address">
            Business Email Address
          </label>
          <input
            onChange={handleFormChange}
            className="max-w-[60%] outline-none	border-b-2 focus:border-orange-400 transition mb-2"
            placeholder="Type email address here"
            type="email"
            name="email_address"
            id="email_address"
          />
        </div>

        <div className="flex flex-col justify-center shadow-xl rounded-xl h-full py-4 px-8 w-[35%] bg-white mb-8">
          <h2 className="text-lg underline font-bold">Business Tags</h2>
          <h3 className="text-sm">
            These tags will be used to search and filter a business on the app.
            Accurate labeling will increase the chance of the business being
            seen by the right user.
          </h3>
          <label className="pt-4 text-lg" for="categories">
            Categories
          </label>
          <ul>
            {categories.map((category) => (
              <li key={category.id} className="py-1 ">
                <label className="">
                  <input
                    type="checkbox"
                    className="custom-checkbox text-orange-500 bg-orange-100"
                    checked={category.checked}
                    onChange={() => handleChangeCategory(category.id)}
                  />{" "}
                  {capitalizeWords(category.text)}
                </label>
              </li>
            ))}
          </ul>
          <label className="pt-4 text-lg" for="dietary-restriction">
            Dietary Restrictions
          </label>
          <ul>
            {DR.map((dr) => (
              <li key={dr.id} className="py-1">
                <label>
                  <input
                    type="checkbox"
                    className="custom-checkbox text-orange-500 bg-orange-100"
                    checked={dr.checked}
                    onChange={() => handleChangeDR(dr.id)}
                  />{" "}
                  {capitalizeWords(dr.text)}
                </label>
              </li>
            ))}
          </ul>
          <label className="keywords pt-4 text-lg">Keywords</label>
          <h2 className="text-sm pb-4">
            Add up to 5 keywords for the business (examples: cafe, game,
            black-owned, etc)
          </h2>
          {keywords.map((keyword, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <input
                type="text"
                className=" outline-none	border-b-2 focus:border-orange-400 transition"
                value={keyword}
                onChange={(e) => handleKeywordChange(index, e.target.value)}
                placeholder="Enter keyword"
                style={{ marginRight: "10px" }}
              />
              {keywords.length > 1 && (
                <div
                  onClick={() => handleRemoveKeyword(index)}
                  style={{
                    padding: "5px 10px",
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                >
                  -
                </div>
              )}
              {index === keywords.length - 1 && keywords.length < 5 && (
                <div
                  onClick={handleAddKeyword}
                  style={{ padding: "5px 10px", cursor: "pointer" }}
                >
                  +
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="flex flex-col justify-center shadow-xl rounded-xl h-full py-4 px-8 w-[35%] bg-white mb-8">
          <h2 className="text-lg underline font-bold">
            Create a Campfire Business Account
          </h2>

          <h3 className="text-sm">
            This section creates a Campfire Business App login credential for
            the business owner - input the business owner's email and give them
            a temporary password (e.g doug123).
            <br />
            <br />
            If this account already exists, and you are simply just adding a new
            business for this account, then the password{" "}
            <b>will not be reset</b>, so just put whatever you want in the
            password field.
            <br />
            <br />
            <b>
              IMPORTANT: Note down the email and temporary password as these
              will be their login credentials
            </b>
          </h3>
          <label className="required-field pt-4" for="business_owner_email">
            Business Owner's Email
          </label>
          <h2 className="text-sm pb-4 ">
            This will be the business owner's login email.
          </h2>
          <input
            onChange={handleFormChange}
            className="max-w-[60%] outline-none	border-b-2 focus:border-orange-400 transition mb-2"
            placeholder="Type business owner's email here"
            type="email"
            name="business_owner_email"
            id="business_owner_email"
            required
          />
          <label className="required-field pt-4" for="dummy_password">
            Temporary Password
          </label>
          <h2 className="text-sm pb-4 ">
            Give a temporary password (keep it simple). This will be the
            business owner's login password.
          </h2>
          <input
            onChange={handleFormChange}
            className="max-w-[60%] outline-none	border-b-2 focus:border-orange-400 transition mb-2"
            placeholder="Type password here"
            type="text"
            name="dummy_password"
            id="dummy_password"
            required
          />
        </div>
        <button
          disabled={isLoading}
          type="submit"
          className="mt-[1em] rounded-md shadow-2xl border-[2px] border-[#FF840D]	 text-2xl py-[0.5em] bg-[#FFF5E1]  w-fit px-[1em]  hover:bg-[#FFDBB1] transition  "
          style={{
            opacity: isLoading ? 0.5 : 1,
          }}
        >
          {isLoading ? <LoadingIcon /> : "Submit"}
        </button>
      </form>

      <Modal show={showModal} onClose={handleCloseModal}>
        <h2>
          1. Search for the business on Google. For this example, we are
          searching for Gentleman Jim's Restaurant.
        </h2>

        <img
          src="/images/how_to_find_lat_long1.png"
          alt="how_to_find_lat_long1.png"
          style={{ width: "100%" }}
        />

        <hr className="my-4 border-1 border-black" />
        <h2>
          2. Go to Maps, and find the red pin of the business on the map. Then,
          zoom in as close as possible to the business, and right-click on the
          pin to find the lat and long of the business. Copy these values over
          to the form.{" "}
          <b>VERY IMPORTANT: Make sure to include negative sign.</b>{" "}
          <b>VERY IMPORTANT: ZOOM IN AS CLOSE AS POSSIBLE!</b>
        </h2>

        <img
          src="/images/how_to_find_lat_long2.png"
          alt="how_to_find_lat_long2.png"
          style={{ width: "100%" }}
        />

        <div className="w-full flex py-4 justify-center items-center self-center">
          <button
            onClick={handleCloseModal}
            className="text-xl px-8 py-4 bg-orange-200 rounded shadow-lg hover:bg-orange-400 transition"
          >
            Close
          </button>
        </div>
      </Modal>

      <Modal
        show={showModalAfter}
        onClose={() => {
          handleCloseModalAfter(modalMetadata["redirect"]);
        }}
      >
        <h2 className="text-xl " style={{ whiteSpace: "pre-line" }}>
          {modalMetadata["text"]}
        </h2>
        <div className="w-full flex py-4 justify-center items-center self-center">
          <button
            onClick={() => {
              handleCloseModalAfter(modalMetadata["redirect"]);
            }}
            className="text-xl px-8 py-4 bg-orange-200 rounded shadow-lg hover:bg-orange-400 transition"
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default HermesAddBusinessAndUser;
