import React from "react";
import {
  FaTiktok,
  FaXTwitter,
  FaFacebook,
  FaInstagram,
  FaThreads,
  FaSnapchat,
  FaYoutube,
  FaLinkedinIn,
  FaAppStoreIos,
  FaGooglePlay,
} from "react-icons/fa6";
import {
  BUSINESS_APP_STORE_LINK,
  BUSINESS_PLAY_STORE_LINK,
  CONSUMER_APP_STORE_LINK,
  CONSUMER_PLAY_STORE_LINK,
} from "../helpers/constants";

const Footer = ({ downloadRef }) => {
  return (
    <div className="text-[#FEFFFF]  relative h-fit bg-[#0e1111] flex flex-col justify-around items-center py-16 px-8 ">
      {/* Company email, address, etc */}
      <div className="flex flex-col xl:flex-row items-center  xl:items-start justify-around w-full">
        <div className="leading-none  p-[1em]">
          <p className="text-xl font-bold">Contact</p>
          <br></br>
          <p className="leading-none">Email: campfireloyalty@gmail.com</p>
          <br></br>
          <p className="leading-none">Phone: (248) 660-9169</p>
        </div>
        <div className="leading-none  p-[1em]">
          <p className="text-xl font-bold">Social</p>
          <div className="flex flex-row flex-start items-center py-[0.5em]">
            <a href="https://www.instagram.com/campfireloyalty?utm_source=ig_web_button_share_sheet">
              <FaInstagram
                className="mr-4 cursor-pointer transition p-3 rounded hover:bg-white text-white hover:text-[#0e1111] "
                size={56}
              ></FaInstagram>
            </a>
            <a href="https://twitter.com/campfirepoints">
              <FaXTwitter
                className="mr-4 cursor-pointer transition p-3 rounded hover:bg-white text-white hover:text-[#0e1111] "
                size={56}
              ></FaXTwitter>
            </a>
            <a href="https://www.facebook.com/people/Campfire-Loyalty/61556710206845">
              <FaFacebook
                className="mr-4 cursor-pointer transition p-3 rounded hover:bg-white text-white hover:text-[#0e1111] "
                size={56}
              ></FaFacebook>
            </a>
          </div>
          <div className="flex flex-row flex-start items-center py-[0.5em]">
            <a href="https://www.youtube.com/channel/UCrMZyDbNO4PvL9CnAcPkOug">
              <FaYoutube
                className="mr-4 cursor-pointer transition p-3 rounded hover:bg-white text-white hover:text-[#0e1111] "
                size={56}
              ></FaYoutube>
            </a>
            <a href="https://www.linkedin.com/company/campfire-loyalty">
              <FaLinkedinIn
                className="mr-4 cursor-pointer transition p-3 rounded hover:bg-white text-white hover:text-[#0e1111] "
                size={56}
              ></FaLinkedinIn>
            </a>
          </div>
        </div>
        <div
          ref={downloadRef}
          className="leading-none  p-[1em] items-center xl:items-start justify-center flex flex-col"
        >
          <p className="text-xl font-bold">Get the Consumer App</p>
          <a href={CONSUMER_APP_STORE_LINK}>
            <div className="mt-[0.5em] flex flex-row  justify-between cursor-pointer hover:bg-white text-white hover:text-[#0e1111] transition rounded-full  border-solid border-2 border-white p-[0.5em] px-[2em] items-center">
              <FaAppStoreIos size={36} className="mr-4"></FaAppStoreIos>
              <p>App Store</p>
            </div>
          </a>
          <a href={CONSUMER_PLAY_STORE_LINK}>
            <div className="mt-[0.5em] flex flex-row  justify-between cursor-pointer hover:bg-white text-white hover:text-[#0e1111] transition rounded-full  border-solid border-2 border-white p-[0.5em] px-[2em] items-center">
              <FaGooglePlay size={36} className="mr-4"></FaGooglePlay>
              <p>Play Store</p>
            </div>
          </a>
        </div>
        <div className="leading-none  p-[1em] items-center xl:items-start justify-center flex flex-col">
          <p className="text-xl font-bold">Get the Business App</p>
          <a href={BUSINESS_APP_STORE_LINK}>
            <div className="mt-[0.5em] flex flex-row  justify-between cursor-pointer hover:bg-white text-white hover:text-[#0e1111] transition rounded-full  border-solid border-2 border-white p-[0.5em] px-[2em] items-center">
              <FaAppStoreIos size={36} className="mr-4"></FaAppStoreIos>
              <p>App Store</p>
            </div>
          </a>
          <a href={BUSINESS_PLAY_STORE_LINK}>
            <div className="mt-[0.5em] flex flex-row  justify-between cursor-pointer hover:bg-white text-white hover:text-[#0e1111] transition rounded-full  border-solid border-2 border-white p-[0.5em] px-[2em] items-center">
              <FaGooglePlay size={36} className="mr-4"></FaGooglePlay>
              <p>Play Store</p>
            </div>
          </a>
        </div>
      </div>
      {/*  */}
      <div className="w-full my-[1em] border-b border-gray-400"></div>
      {/* Download on App Store */}
      <div>
        <p>
          All rights reserved &copy; Campfire Loyalty, LLC{" "}
          {new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default Footer;
