import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { FaArrowRotateLeft, FaArrowRotateRight } from "react-icons/fa6";
import Modal from "react-modal";
import { IoCloseSharp } from "react-icons/io5";
import { UserContext } from "../../context/UserProvider";
import { PiRobot } from "react-icons/pi";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

import "../../css/hermes.css";
import Spinner from "../../components/Spinner";
import {
  convertISO8601ESTtoISO8601UTC,
  convertISO8601UTCtoISO8601EST,
  formatISO8601Date,
  formatISO8601DateAgnostic,
} from "../../helpers/timeHelper";
import { DEBOUNCE_MILLISECONDS, environment_ } from "../../helpers/constants";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
  },
};

const BUSINESSES = [
  {
    id: 1,
    name: "Bernard's Seafood",
  },
  {
    id: 2,
    name: "Khadi's Cuisine",
  },
  {
    id: 3,
    name: "The Deep End Cafe",
  },
  {
    id: 4,
    name: "Issa's Pizza",
  },
  {
    id: 5,
    name: "Encuentro Latino",
  },
  {
    id: 6,
    name: "Infinitee Custom",
  },
  {
    id: 7,
    name: "Gora's Grill",
  },
];

const BusinessSearchResults = ({
  searchText,
  setBusinessFocus,
  setSelectedBusiness,
  businessSearchList,
  isBeingReviewedLoading,
}) => {
  const [businesses, setBusinesses] = useState([]);
  const debounceTimeout = useRef(null);
  useEffect(() => {
    clearTimeout(debounceTimeout.current);
    const search = () => {
      if (!searchText) {
        setBusinesses(businessSearchList);
      }

      const regex = new RegExp(
        searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"),
        "i"
      );

      const filteredItems = businessSearchList.filter((item) => {
        return regex.test(item.name);
      });

      setBusinesses(filteredItems);
    };
    debounceTimeout.current = setTimeout(() => {
      search();
    }, DEBOUNCE_MILLISECONDS);

    return () => clearTimeout(debounceTimeout.current);
  }, [searchText]);

  return (
    <div class="flex flex-col absolute top-[100%] w-full  border border-[#140901]  overflow-y-scroll	max-h-[200px]">
      {isBeingReviewedLoading ? (
        <Spinner />
      ) : (
        businesses.map((item) => {
          return (
            <button
              onClick={(e) => {
                e.preventDefault();
                setBusinessFocus(false);
                setSelectedBusiness(item);
              }}
              class="bg-[#FFF5E1] text-left border-b border-b-gray-400 "
            >
              <h2 class="text-lg p-2">{item.name}</h2>
            </button>
          );
        })
      )}
    </div>
  );
};

const REJECTION_CHECKLIST = [
  {
    title: "Duplicate",
    checked: false,
    name: "duplicate",
    label:
      "This receipt has been uploaded before, either by you or by someone else.",
  },
  {
    title: "Not A Receipt",
    checked: false,
    name: "not-a-receipt",
    label: "The uploaded image is not a receipt.",
  },
  {
    title: "Not Readable",
    checked: false,
    name: "not-readable",
    label:
      "The uploaded receipt is not readable; this could imply that the uploaded receipt has poor lighting, is heavily scrambled, blurred, faded, or generally unreadable.",
  },
  {
    title: "Not a Participating Business",
    checked: false,
    name: "not-a-participating-business",
    label:
      "The uploaded receipt doesn't come from one of our participating businesses.",
  },
  {
    title: "Missing/Invalid Date",
    checked: false,
    name: "missing-invalid-date",
    label:
      "Date of transaction is missing or doesn't include at least one of year, month, day, or minute.",
  },
  {
    title: "Expired",
    checked: false,
    name: "expired",
    label: "The uploaded receipt is more than 30 days old.",
  },
  {
    title: "Not Complete",
    checked: false,
    name: "not-complete",
    label:
      "The entire receipt is not included; only parts of the receipt are uploaded.",
  },
  {
    title: "Altered",
    checked: false,
    name: "altered",
    label:
      "The uploaded receipt is altered in some ways, this can mean the uploaded receipt is doodled, edited, heavily scratched, has pen marks, etc.",
  },
  {
    title: "Uploaded image is an Invoice",
    checked: false,
    name: "uploaded-invoice",
    label: "The uploaded image is an invoice or a generated PDF.",
  },
  {
    title: "Counterfeit",
    checked: false,
    name: "counterfeit",
    label: "The uploaded receipt is counterfeited.",
  },
];

function attrExists(attr) {
  return attr != null && !Number.isNaN(attr) && attr != "NaN";
}

/**
 *
 * @param {receiptActivities} Array<Activity> : the receipt activities that is similar
 *                                              to the current receipt
 * Activity : {
 *  urls: Array<string>;
 *  businessName: Array<string>;
 *  businessId: Array<string>;
 *  ...
 * }
 */

const SimilarReceiptCard = ({ index, receiptActivity, setSelectedItem }) => {
  return (
    <button
      class="flex flex-1 hover:bg-[#FF840D] bg-[#FFF5E1] p-4 transition w-full items-center border-x-2 border-x-[#140901]  border-b-2 border-b-[#140901]"
      style={{
        borderTop: index == 0 ? "2px solid #140901" : 0,
      }}
      onClick={() => {
        setSelectedItem(receiptActivity);
      }}
    >
      <div class="flex flex-[5]">Review ID: {receiptActivity.id}</div>
      <div class="flex flex-1 justify-end items-center ">
        <FaChevronRight />
      </div>
    </button>
  );
};

const SimilarReceiptScreen = ({ selectedItem, setSelectedItem }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [degrees, setDegrees] = useState([]);
  const [loadingStates, setLoadingStates] = useState([]);

  useEffect(() => {
    setDegrees(Array(selectedItem.urls.length).fill(0));
    setLoadingStates(Array(selectedItem.urls.length).fill(true));
  }, [selectedItem.urls]);

  const handleImageLoad = (index) => {
    setLoadingStates((prevLoadingStates) => {
      const newLoadingStates = [...prevLoadingStates];
      newLoadingStates[index] = false;
      return newLoadingStates;
    });
  };

  const rotateImageNegative = (index) => {
    setDegrees((prevDegrees) => {
      const newDegrees = [...prevDegrees];
      newDegrees[index] = newDegrees[index] - 90; // Rotate by 90 degrees
      return newDegrees;
    });
  };
  const rotateImagePositive = (index) => {
    setDegrees((prevDegrees) => {
      const newDegrees = [...prevDegrees];
      newDegrees[index] = newDegrees[index] + 90; // Rotate by 90 degrees
      return newDegrees;
    });
  };

  const prevSlide = () => {
    setCurrentIndex((val) => {
      if (val == 0) return selectedItem.urls.length - 1;

      return val - 1;
    });
  };

  const nextSlide = () => {
    setCurrentIndex((val) => (val + 1) % selectedItem.urls.length);
  };

  return (
    <div class="relative h-full ">
      <button
        class="flex items-center  top-0 left-0"
        onClick={() => {
          setSelectedItem(null);
        }}
      >
        <FaChevronLeft size={25} />
        <h2 class="text-xl">Go Back</h2>
      </button>
      <div class="mt-4  h-[80vh]">
        <div className="image-container">
          <div>
            <h2 class="text-xl">
              Similar Receipt {currentIndex + 1} / {selectedItem.urls.length}
            </h2>
          </div>
          <TransformWrapper initialScale={1} minScale={0.5} maxScale={3}>
            <TransformComponent
              wrapperStyle={{
                border: "1px solid #140901",
                height: "100%",
                width: "100%",
              }}
              contentStyle={{ height: "100%", width: "100%" }}
              // wrapperClass="image-wrapper"
              // contentClass="image-content"
            >
              <img
                src={selectedItem.urls[currentIndex]}
                onLoad={() => handleImageLoad(currentIndex)}
                alt="Zoomable"
                className="responsive-image"
                // class="object-contain w-[100%] h-[100%] bg-contain"
                style={{
                  display: loadingStates[currentIndex] ? "none" : "block",
                  transform: `rotate(${degrees[currentIndex]}deg)`,
                }}
              />
              {loadingStates[currentIndex] && <Spinner />}
            </TransformComponent>
            {currentIndex != 0 && (
              <button onClick={() => prevSlide()} className="left-container">
                <FaChevronCircleLeft size={50} />
              </button>
            )}
            {currentIndex != selectedItem.urls.length - 1 && (
              <button onClick={() => nextSlide()} className="right-container">
                <FaChevronCircleRight size={50} />
              </button>
            )}
          </TransformWrapper>
          <div className="tools-container">
            <button
              class="pr-4"
              onClick={() => {
                rotateImageNegative(currentIndex);
              }}
            >
              <FaArrowRotateLeft size={30}></FaArrowRotateLeft>
            </button>
            <button
              onClick={() => {
                rotateImagePositive(currentIndex);
              }}
            >
              <FaArrowRotateRight size={30}></FaArrowRotateRight>
            </button>
          </div>
        </div>

        {/* <h2>Rejection Reasons (if status is rejected): {selectedItem.rejectionReasons}</h2> */}
      </div>

      <h2 class="text-xl font-bold underline">More Details</h2>
      <h2 class="">
        <h2 class="text-gray-400">Review ID:</h2>
        {selectedItem.id}
      </h2>
      <h2 class="">
        <h2 class="text-gray-400">Business ID: </h2>
        {selectedItem.businessId}
      </h2>
      <h2 class="">
        <h2 class="text-gray-400">Business Name:</h2>
        {selectedItem.businessName}
      </h2>
      <h2 class="">
        <h2 class="text-gray-400">User Email: </h2>
        {selectedItem.userEmail}
      </h2>
      <h2 class="">
        <h2 class="text-gray-400">Submitted Datetime:</h2>
        {selectedItem.submittedDatetime}
      </h2>
      <h2 class="">
        <h2 class="text-gray-400">Last Updated Datetime:</h2>
        {selectedItem.lastUpdatedDatetime}
      </h2>
      <h2 class="">
        <h2 class="text-gray-400">Receipt Datetime:</h2>
        {selectedItem.receiptDatetime}
      </h2>
      <h2 class="">
        <h2 class="text-gray-400">Receipt Subtotal:</h2>
        {selectedItem.subtotal}
      </h2>
      <h2 class="">
        <h2 class="text-gray-400">Review Status:</h2>
        {selectedItem.reviewStatus}
      </h2>
      <h2 class="">
        <h2 class="text-gray-400">Reviewer's Email:</h2>
        {selectedItem.reviewerEmail}
      </h2>
      <h2 class="">
        <h2 class="text-gray-400">Reviewer's Notes:</h2>
        {selectedItem.reviewerNotes}
      </h2>
    </div>
  );
};

const SimilarReceipts = ({ notEnoughInformation, receiptActivities }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <div class="p-4">
      {notEnoughInformation ? (
        <h2>
          Please fill in Business Name, Datetime, and Subtotal to initiate a
          search automatically
        </h2>
      ) : receiptActivities == null ? (
        <Spinner />
      ) : receiptActivities.length == 0 ? (
        <h2>No similar receipts found!</h2>
      ) : selectedItem ? (
        <SimilarReceiptScreen
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      ) : (
        receiptActivities.map((item, index) => {
          return (
            <SimilarReceiptCard
              index={index}
              receiptActivity={item}
              setSelectedItem={setSelectedItem}
            />
          );
        })
      )}
    </div>
  );
};

const ReceiptTemplate = ({ businessId }) => {
  const { user } = useContext(UserContext);
  const [urls, setUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [degrees, setDegrees] = useState([]);
  const [loadingStates, setLoadingStates] = useState([]);

  useEffect(() => {
    if (!businessId) return;

    const execute = async () => {
      setIsLoading(true);
      fetch(
        process.env.REACT_APP_API_URL +
          environment_ +
          `/receipt_template?business_id=${parseInt(businessId)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await user.getIdToken()}`,
          },
        }
      )
        .then(async (response) => {
          if (!response.ok) {
            console.log("Error in fetching receipt template");
            console.log(await response.json());
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then(async (data) => {
          console.log("Success in fetching receipt template", data);
          setUrls(data["urls"]);
        })
        .catch((error) => {
          console.error(
            "There was a problem with the fetch operation with fetching receipt template:",
            error
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    execute();
  }, [businessId]);

  useEffect(() => {
    setDegrees(Array(urls.length).fill(0));
    setLoadingStates(Array(urls.length).fill(true));
  }, [urls]);

  const handleImageLoad = (index) => {
    setLoadingStates((prevLoadingStates) => {
      const newLoadingStates = [...prevLoadingStates];
      newLoadingStates[index] = false;
      return newLoadingStates;
    });
  };

  const rotateImageNegative = (index) => {
    setDegrees((prevDegrees) => {
      const newDegrees = [...prevDegrees];
      newDegrees[index] = newDegrees[index] - 90; // Rotate by 90 degrees
      return newDegrees;
    });
  };
  const rotateImagePositive = (index) => {
    setDegrees((prevDegrees) => {
      const newDegrees = [...prevDegrees];
      newDegrees[index] = newDegrees[index] + 90; // Rotate by 90 degrees
      return newDegrees;
    });
  };

  const prevSlide = () => {
    setCurrentIndex((val) => {
      if (val == 0) return urls.length - 1;

      return val - 1;
    });
  };

  const nextSlide = () => {
    setCurrentIndex((val) => (val + 1) % urls.length);
  };

  return (
    <div class=" h-[90vh]">
      {businessId ? (
        isLoading ? (
          <Spinner />
        ) : urls.length == 0 ? (
          <h2>This business has no uploaded a receipt template yet</h2>
        ) : (
          <div className="image-container">
            <div>
              <h2 class="text-xl">
                Template {currentIndex + 1} / {urls.length}
              </h2>
            </div>
            <TransformWrapper initialScale={1} minScale={0.5} maxScale={3}>
              <TransformComponent
                wrapperStyle={{
                  border: "1px solid #140901",
                  height: "100%",
                  width: "100%",
                }}
                contentStyle={{ height: "100%", width: "100%" }}
                // wrapperClass="image-wrapper"
                // contentClass="image-content"
              >
                <img
                  src={urls[currentIndex]}
                  onLoad={() => handleImageLoad(currentIndex)}
                  alt="Zoomable"
                  className="responsive-image"
                  // class="object-contain w-[100%] h-[100%] bg-contain"
                  style={{
                    display: loadingStates[currentIndex] ? "none" : "block",
                    transform: `rotate(${degrees[currentIndex]}deg)`,
                  }}
                />
                {loadingStates[currentIndex] && <Spinner />}
              </TransformComponent>
              {currentIndex != 0 && (
                <button onClick={() => prevSlide()} className="left-container">
                  <FaChevronCircleLeft size={50} />
                </button>
              )}
              {currentIndex != urls.length - 1 && (
                <button onClick={() => nextSlide()} className="right-container">
                  <FaChevronCircleRight size={50} />
                </button>
              )}
            </TransformWrapper>
            <div className="tools-container">
              <button
                class="pr-4"
                onClick={() => {
                  rotateImageNegative(currentIndex);
                }}
              >
                <FaArrowRotateLeft size={30}></FaArrowRotateLeft>
              </button>
              <button
                onClick={() => {
                  rotateImagePositive(currentIndex);
                }}
              >
                <FaArrowRotateRight size={30}></FaArrowRotateRight>
              </button>
            </div>
          </div>
        )
      ) : (
        <h2 class="p-4">
          ❗ Selected a business from the "Review" Tab, so I can search it up
          for you
        </h2>
      )}
    </div>
  );
};

const HermesReview = () => {
  const [imageLoading, setImageLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [approved, setApproved] = useState(null);
  const businessInputRef = useRef(null);
  const [businessFocus, setBusinessFocus] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [selectedDatetimeISO8601, setSelectedDatetimeISO8601] = useState(null);
  const [subtotal, setSubtotal] = useState(null);
  const [notes, setNotes] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const { user } = useContext(UserContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const props = location.state?.props;
  const [degrees, setDegrees] = useState([]);
  const [loadingStates, setLoadingStates] = useState([]);
  const [rejectionReasons, setRejectionReasons] = useState(REJECTION_CHECKLIST);
  const [otherReason, setOtherReason] = useState("");
  const [selectedScreen, setSelectedScreen] = useState(0);
  const [similarReceiptActivities, setSimilarReceiptActivities] =
    useState(null);
  const [
    similarReceiptNotEnoughInformation,
    setSimilarReceiptNotEnoughInformation,
  ] = useState(false);
  const [isBeingReviewed, setIsBeingReviewed] = useState(false);
  const [isBeingReviewedError, setIsBeingReviewedError] = useState("");
  const [isBeingReviewedLoading, setIsBeingReviewedLoading] = useState(true);
  const [businessSearchList, setBusinessSearchList] = useState([]);
  const debounceTimeout = useRef(null);

  function validateInputs() {
    let alertMsg = null;
    try {
      if (attrExists(subtotal) && parseInt(subtotal) < 0) {
        alertMsg = "Subtotal cannot be negative";
      }
      if (attrExists(subtotal) && parseFloat(subtotal) > 1000) {
        alertMsg = "Subtotal cannot be more than a 1000";
      }

      if (
        attrExists(subtotal) &&
        subtotal.toString()[1] != "." &&
        subtotal.toString()[0] == "0"
      ) {
        alertMsg = "Subtotal cannot start with 0";
      }

      if (!attrExists(subtotal)) {
        alertMsg = "Input the subtotal";
      }
      if (!attrExists(selectedDatetimeISO8601)) {
        alertMsg = "Select a datetime";
      }
      if (!attrExists(selectedBusiness)) {
        alertMsg = "You must select one of the businesses from the searchbox";
      }
      if (alertMsg) {
        setIsOpen(false);
        alert(alertMsg);
        return;
      }
      setIsOpen(true);
      setApproved(true);
    } catch (err) {
      setIsOpen(false);
      setApproved(null);
      console.log("Some error happened", err);
      alert("Some error happened, please try again or contact Bernard");
    }
  }

  const handleCheckboxChange = (index) => {
    console.log("Checking", index);
    const newRejectionReasons = [...rejectionReasons];
    newRejectionReasons[index].checked = !newRejectionReasons[index].checked;
    setRejectionReasons(newRejectionReasons);
  };

  const handleImageLoad = (index) => {
    setLoadingStates((prevLoadingStates) => {
      const newLoadingStates = [...prevLoadingStates];
      newLoadingStates[index] = false;
      return newLoadingStates;
    });
  };

  const rotateImageNegative = (index) => {
    setDegrees((prevDegrees) => {
      const newDegrees = [...prevDegrees];
      newDegrees[index] = newDegrees[index] - 90; // Rotate by 90 degrees
      return newDegrees;
    });
  };
  const rotateImagePositive = (index) => {
    setDegrees((prevDegrees) => {
      const newDegrees = [...prevDegrees];
      newDegrees[index] = newDegrees[index] + 90; // Rotate by 90 degrees
      return newDegrees;
    });
  };

  const prevSlide = () => {
    setCurrentIndex((val) => {
      if (val == 0) return props.urls.length - 1;

      return val - 1;
    });
  };

  const nextSlide = () => {
    setCurrentIndex((val) => (val + 1) % props.urls.length);
  };

  const setSelectedBusinessProxy = (item) => {
    businessInputRef.current.value = item.name;
    setBusinessFocus(false);
    setSearchText("");
    setSelectedBusiness(item);
  };

  function formatCurrency(val) {
    // Ensure the value has two decimal places
    try {
      val = parseFloat(val);
      if (isNaN(val)) {
        setSubtotal(null);
      }
      setSubtotal(val.toFixed(2));
    } catch {
      setSubtotal(null);
    }
  }

  function closeModal() {
    setIsOpen(false);
  }

  async function handleSubmitReject() {
    const reasons = rejectionReasons
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.label);
    console.log("Reasons are", reasons);
    if (otherReason) reasons.push(otherReason);
    if (reasons.length == 0) {
      alert("Please select at least one reason");
      return;
    }
    setIsLoading(true);
    setIsPressed(true);
    await fetch(
      process.env.REACT_APP_API_URL + environment_ + `/receipt_activity`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
        body: JSON.stringify({
          isApproved: false,
          userEmail: props.userEmail,
          reviewerNotes: notes,
          reviewId: id,
          reviewerEmail: user.email,
          rejectionReasons: reasons,
        }),
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          console.log("Error in updating receipt activity");
          console.log(await response.json());
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(async (data) => {
        console.log("Success in updating receipt activity", data);
      })
      .catch((error) => {
        console.error(
          "There was a problem with the fetch operation with updating receipt activity:",
          error
        );
      })
      .finally(() => {
        setIsLoading(false);
        navigate("/hermes/dashboard");
      });
  }

  async function handleSubmitApprove() {
    setIsLoading(true);

    setIsPressed(true);
    await fetch(
      process.env.REACT_APP_API_URL + environment_ + `/receipt_activity`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
        body: JSON.stringify({
          isApproved: true,
          businessName: selectedBusiness.name,
          businessId: selectedBusiness.id,
          userEmail: props.userEmail,
          subtotal: subtotal,
          receiptDatetime: convertISO8601ESTtoISO8601UTC(
            selectedDatetimeISO8601
          ),
          reviewerNotes: notes,
          reviewId: id,
          reviewerEmail: user.email,
        }),
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          console.log("Error in updating receipt activity");
          console.log(await response.json());
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(async (data) => {
        console.log("Success in updating receipt activity", data);
      })
      .catch((error) => {
        console.error(
          "There was a problem with the fetch operation with updating receipt activity:",
          error
        );
      })
      .finally(() => {
        setIsLoading(false);
        navigate("/hermes/dashboard");
      });
  }

  useEffect(() => {
    if (!props || !props.urls) {
      navigate("/hermes/dashboard");
      return;
    }
    setDegrees(Array(props.urls.length).fill(0));
    setLoadingStates(Array(props.urls.length).fill(true));
  }, []);

  useEffect(() => {
    if (
      !(
        selectedBusiness &&
        selectedBusiness.name &&
        selectedDatetimeISO8601 &&
        subtotal
      )
    ) {
      setSimilarReceiptNotEnoughInformation(true);
      setSimilarReceiptActivities([]);
      return;
    } else {
      setSimilarReceiptNotEnoughInformation(false);
    }

    clearTimeout(debounceTimeout.current);
    const findSimilarReceipts = async () => {
      fetch(
        process.env.REACT_APP_API_URL +
          environment_ +
          `/similar_receipt?businessId=${selectedBusiness.id.toString()}&receiptDatetime=${convertISO8601ESTtoISO8601UTC(
            selectedDatetimeISO8601
          )}&subtotal=${subtotal.toString()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await user.getIdToken()}`,
          },
        }
      )
        .then(async (response) => {
          if (!response.ok) {
            console.log("Error in fetching similar receipts");
            console.log(await response.json());
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then(async (data) => {
          console.log("Success in fetching similar receipts", data);
          setSimilarReceiptActivities(data["items"]);
        })
        .catch((error) => {
          console.error(
            "There was a problem with the fetch operation with fetching similar receipts:",
            error
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    debounceTimeout.current = setTimeout(
      findSimilarReceipts,
      DEBOUNCE_MILLISECONDS
    );

    return () => clearTimeout(debounceTimeout);
  }, [selectedBusiness, selectedDatetimeISO8601, subtotal]);

  useEffect(() => {
    // if (isBeingReviewedLoading) return;
    const execute = async () => {
      setIsBeingReviewedLoading(true);
      fetch(
        process.env.REACT_APP_API_URL +
          environment_ +
          `/businesses?reviewId=${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await user.getIdToken()}`,
          },
        }
      )
        .then(async (response) => {
          if (!response.ok) {
            console.log("Error in getting businesses ");
            const res = await response.json();
            console.log(res);
            setIsBeingReviewed(true);
            setIsBeingReviewedError(res["error"]);
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then(async (data) => {
          console.log("Success in getting businesses", data);
          setBusinessSearchList(data);
          // businessSearchList
        })
        .catch((err) => {
          console.log("ERROR IS ", err.message);
          console.error(
            "There was a problem with the fetch operation with getting businesses:",
            err
          );
        })
        .finally(() => {
          setIsBeingReviewedLoading(false);
        });
    };

    execute();

    // refresh the privilege every 5 mins
    const intervalID = setInterval(() => {
      // TODO: this is not ideal, we should prob have another endpoint
      // specifically jsut for updating lastUpdatedDatetime
      // This is because -> 1st this API fetches busineses 2nd This
      //                    API doesn't allow modifying LUD if it's not exired yet,
      //                    thus there's a small window someone can come in and kick out the original reviewer
      execute();
    }, 300000);

    return () => {
      clearInterval(intervalID);
    };
  }, []);

  function setIsBeingReviewedProxy(bool_) {
    navigate("/hermes/dashboard");
  }
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel={approved == true ? "Approve" : "Reject"}
      >
        <div class="flex flex-1 flex-col w-[500px]">
          <div class="flex flex-row justify-between items-center p-[8px]  ">
            <p class=" text-2xl font-bold ">
              {approved
                ? "Review Information"
                : "Specify reason(s) for rejection"}
            </p>
            <button onClick={closeModal} class="">
              <IoCloseSharp size={30}></IoCloseSharp>
            </button>
          </div>
          <div class="flex flex-1  p-[8px] ">
            {approved == true ? (
              <div class="flex flex-1 flex-col">
                <h2 class="text-gray-400">Business Name:</h2>
                {selectedBusiness && (
                  <h2 class="mb-4">{selectedBusiness.name}</h2>
                )}
                <h2 class="text-gray-400">Datetime (EST):</h2>
                <h2 class="mb-4">
                  {formatISO8601DateAgnostic(selectedDatetimeISO8601)}
                </h2>
                <h2 class="text-gray-400">Subtotal:</h2>
                <h2 class="mb-4">{subtotal}</h2>
                <h2 class="text-gray-400">Notes:</h2>
                <h2 class="mb-4">{notes}</h2>
                <button
                  disabled={isPressed}
                  style={{
                    opacity: isPressed ? 0.5 : 1,
                  }}
                  onClick={handleSubmitApprove}
                  class="self-center mt-[2em] rounded-b-xl rounded-t-md shadow-2xl border-r-[2px] border-r-[#FF840D]	 text-2xl py-[0.5em] bg-[#FFF5E1]  w-fit px-[1em] border-b-[4px] border-b-[#FF840D]  hover:bg-[#FFDBB1] transition"
                >
                  {isLoading ? <Spinner></Spinner> : "Confirm Submit"}
                </button>
              </div>
            ) : (
              <div class="flex flex-1 flex-col 	max-h-[80vh]">
                {rejectionReasons.map((item, index) => {
                  return (
                    <div class="flex flex-col my-2" key={index}>
                      <h2 class="text-xl underline mb-2">{item.title}</h2>
                      <div class="flex flex-row items-center mb-2">
                        <input
                          class="mr-4"
                          type="checkbox"
                          name={item.name}
                          id={item.name}
                          onChange={() => handleCheckboxChange(index)}
                        />
                        <label for={item.name}>{item.label}</label>
                      </div>
                    </div>
                  );
                })}
                <div class="flex flex-col my-2">
                  <h2 class="text-xl underline mb-2">
                    Other (if filled up, will be displayed to the user)
                  </h2>
                  <div class="flex flex-row items-center mb-2">
                    <textarea
                      for="other"
                      placeholder="Type in other reason(s) here"
                      class="border-2 border-[#140901] p-1 outline-none w-full"
                      type="text"
                      name="other"
                      id="otherr"
                      onChange={(e) => {
                        e.preventDefault();
                        setOtherReason(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div class="flex flex-col my-2">
                  <button
                    disabled={isPressed}
                    style={{
                      opacity: isPressed ? 0.5 : 1,
                    }}
                    onClick={handleSubmitReject}
                    class="self-center my-[2em] rounded-b-xl rounded-t-md shadow-2xl border-r-[2px] border-r-[#FF840D]	 text-2xl py-[0.5em] bg-[#FFF5E1]  w-fit px-[1em] border-b-[4px] border-b-[#FF840D]  hover:bg-[#FFDBB1] transition"
                  >
                    {isLoading ? <Spinner></Spinner> : "Confirm Submit"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isBeingReviewed}
        onRequestClose={setIsBeingReviewedProxy}
        style={customStyles}
        contentLabel={"review"}
      >
        <div class="flex flex-row justify-between items-center p-[8px]  ">
          <div class="flex flex-1 flex-col w-[500px]">
            <button
              onClick={() => {
                setIsBeingReviewedProxy(false);
              }}
              class=""
            >
              <IoCloseSharp size={30}></IoCloseSharp>
            </button>
            <h2 class="my-4 text-2xl">{isBeingReviewedError}</h2>
          </div>
        </div>
      </Modal>

      {props && props.urls && (
        <div class="flex flex-row  justify-between h-screen">
          <div className="image-container">
            <div class="flex flex-row justify-between items-center w-full">
              <h2 class="text-bold">User Email: {props.userEmail}</h2>
              <h2 class="">
                Submitted on: {formatISO8601Date(props.submittedDatetime)}
              </h2>
            </div>
            <div>
              <h2 class="text-xl">
                Receipt {currentIndex + 1} / {props.urls.length}
              </h2>
            </div>
            <TransformWrapper initialScale={1} minScale={0.5} maxScale={3}>
              <TransformComponent
                wrapperStyle={{
                  border: "1px solid #140901",
                  height: "100%",
                  width: "100%",
                }}
                contentStyle={{ height: "100%", width: "100%" }}
                // wrapperClass="image-wrapper"
                // contentClass="image-content"
              >
                <img
                  src={props.urls[currentIndex]}
                  onLoad={() => handleImageLoad(currentIndex)}
                  alt="Zoomable"
                  className="responsive-image"
                  style={{
                    display: loadingStates[currentIndex] ? "none" : "block",
                    transform: `rotate(${degrees[currentIndex]}deg)`,
                  }}
                />
                {loadingStates[currentIndex] && <Spinner />}
              </TransformComponent>
              {currentIndex != 0 && (
                <button onClick={() => prevSlide()} className="left-container">
                  <FaChevronCircleLeft size={50} />
                </button>
              )}
              {currentIndex != props.urls.length - 1 && (
                <button onClick={() => nextSlide()} className="right-container">
                  <FaChevronCircleRight size={50} />
                </button>
              )}
            </TransformWrapper>
            <div className="tools-container">
              <button
                class="pr-4"
                onClick={() => {
                  rotateImageNegative(currentIndex);
                }}
              >
                <FaArrowRotateLeft size={30}></FaArrowRotateLeft>
              </button>
              <button
                onClick={() => {
                  rotateImagePositive(currentIndex);
                }}
              >
                <FaArrowRotateRight size={30}></FaArrowRotateRight>
              </button>
            </div>
          </div>
          <div class="border-l-[1px] border-l-[#140901] flex flex-1 flex-col min-h-screen h-fit ">
            <div className="hermes-navbar-container">
              <nav className="hermes-navbar">
                <ul className="hermes-navbar-list">
                  <li
                    className="hermes-navbar-item"
                    style={{
                      backgroundColor:
                        selectedScreen == 0 ? "#FF840D" : "white",
                      color: selectedScreen == 0 ? "white" : "#140901",
                    }}
                    onClick={() => {
                      setSelectedScreen(0);
                    }}
                  >
                    Review
                  </li>
                  <li
                    className="hermes-navbar-item"
                    style={{
                      backgroundColor:
                        selectedScreen == 1 ? "#FF840D" : "white",
                      color: selectedScreen == 1 ? "white" : "#140901",
                    }}
                    onClick={() => {
                      setSelectedScreen(1);
                    }}
                  >
                    Receipt Template
                  </li>
                  <li
                    className="hermes-navbar-item"
                    style={{
                      backgroundColor:
                        selectedScreen == 2 ? "#FF840D" : "white",
                      color: selectedScreen == 2 ? "white" : "#140901",
                    }}
                    onClick={() => {
                      setSelectedScreen(2);
                    }}
                  >
                    Similar Receipts (
                    {similarReceiptActivities
                      ? similarReceiptActivities.length
                      : 0}
                    )
                  </li>
                </ul>
              </nav>
            </div>
            {selectedScreen == 0 ? (
              <form class="p-[16px] flex flex-col justify-between flex-1">
                <div>
                  <div class="flex flex-col  min-w-[300px] max-w-[50%] relative">
                    <label for="businessName">Business Name</label>
                    <input
                      ref={businessInputRef}
                      type="text"
                      class="border-2 border-[#140901] p-1 outline-none"
                      id="businessName"
                      name="businessName"
                      value={
                        selectedBusiness ? selectedBusiness.name : searchText
                      }
                      placeholder="Search for a business here"
                      onChange={(event) => {
                        setSelectedBusiness(null);
                        setSearchText(event.target.value);
                      }}
                      onFocus={() => setBusinessFocus(true)}
                    />
                    {businessFocus && searchText.length > 0 && (
                      <BusinessSearchResults
                        searchText={searchText}
                        setBusinessFocus={setBusinessFocus}
                        setSelectedBusiness={setSelectedBusinessProxy}
                        businessSearchList={businessSearchList}
                        isBeingReviewedLoading={isBeingReviewedLoading}
                      ></BusinessSearchResults>
                    )}
                  </div>
                  <h2 class="text-md text-gray-400">
                    Note: You must select the business from one of the dropdown
                  </h2>
                  <div className="flex flex-col mt-[2em] min-w-[300px] max-w-[50%] ">
                    <label for="datetime">Datetime (EST)</label>
                    <input
                      class="border-2 border-[#140901] p-1 outline-none"
                      aria-label="Date and time"
                      type="datetime-local"
                      id="datetime"
                      name="datetime"
                      value={selectedDatetimeISO8601}
                      placeholder="Select a date and time"
                      step="60"
                      onChange={(e) => {
                        e.preventDefault();
                        setSelectedDatetimeISO8601(e.target.value); // Default to UTC
                      }}
                    />
                    <h2 class="text-md text-gray-400">
                      Note: If the transaction date doesn't include second, fill
                      in '00' for the seconds field
                    </h2>
                  </div>
                  <div className="flex flex-col mt-[2em] min-w-[300px] max-w-[50%] ">
                    <label for="subtotal">Subtotal (pre-tax)</label>
                    <input
                      class="border-2 border-[#140901] p-1 outline-none"
                      type="number"
                      id="subtotal"
                      name="subtotal"
                      step="0.01"
                      value={subtotal}
                      min="0"
                      pattern="^\\$?(([1-9](\\d*|\\d{0,2}(,\\d{3})*))|0)(\\.\\d{1,2})?$"
                      placeholder="Input subtotal"
                      value={subtotal}
                      onBlur={() => {
                        formatCurrency(subtotal);
                      }}
                      onChange={(e) => {
                        e.preventDefault();
                        setSubtotal(e.target.value);
                      }}
                    />
                  </div>
                  <div className="flex flex-col mt-[2em] min-w-[300px] max-w-[50%] ">
                    <label for="Notes">Notes (for internal records)</label>
                    <textarea
                      class="border-2 border-[#140901] p-1 outline-none"
                      type="text"
                      id="notes"
                      name="notes"
                      value={notes}
                      placeholder="Write some notes here.."
                      onChange={(e) => {
                        e.preventDefault();
                        setNotes(e.target.value);
                      }}
                    />
                  </div>
                  {similarReceiptActivities &&
                    similarReceiptActivities.length > 0 && (
                      <button
                        onClick={() => {
                          setSelectedScreen(2);
                        }}
                        className="flex  flex-row mt-[2em] max-w-[50%] justyfi-center items-center bg-[#FFF5E1] border border-[#140901] shadow-xl rounded-xl p-2"
                      >
                        <PiRobot size={35} class="pr-2"></PiRobot>
                        <h2>
                          I found {similarReceiptActivities.length} similar
                          receipts for you!
                        </h2>
                      </button>
                    )}
                </div>
                <div className="flex flex-col items-center mt-[2em] min-w-[300px] max-w-[50%] ">
                  <h2 class="text-md text-gray-400">
                    Remember to check for duplicates
                  </h2>
                  <div class="flex flex-row w-[100%] justify-around mt-[1em]">
                    <button
                      class="rounded-b-xl rounded-t-md shadow-2xl border-r-[2px] border-r-[#FF840D]	 text-2xl py-[0.5em] bg-[#FFF5E1]  w-fit px-[1em] border-b-[4px] border-b-[#FF840D]  hover:bg-[#FFDBB1] transition"
                      onClick={(e) => {
                        e.preventDefault();
                        validateInputs();
                      }}
                    >
                      Approve
                    </button>
                    <button
                      class="rounded-b-xl rounded-t-md shadow-2xl border-r-[2px] border-r-[#FF840D]	 text-2xl py-[0.5em] bg-[#FFF5E1]  w-fit px-[1em] border-b-[4px] border-b-[#FF840D]  hover:bg-[#FFDBB1] transition"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsOpen(true);
                        setApproved(false);
                      }}
                    >
                      Reject
                    </button>
                  </div>
                </div>
              </form>
            ) : selectedScreen == 1 ? (
              <ReceiptTemplate
                businessId={selectedBusiness && selectedBusiness.id}
              />
            ) : (
              <SimilarReceipts
                notEnoughInformation={similarReceiptNotEnoughInformation}
                receiptActivities={similarReceiptActivities}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default HermesReview;
