import React, { useEffect } from "react";

const HermesAddBusinessUser = () => {
  useEffect(() => {
    document.title = "Add a Business User";
  }, []);

  return (
    <div class="flex flex-col justify-center items-center min-h-screen p-16">
      HermesAddBusinessUser
    </div>
  );
};

export default HermesAddBusinessUser;
