import React, { createContext, useEffect, useState } from "react";
import { FIREBASE_AUTH_HERMES } from "../FirebaseConfig";
import Spinner from "../components/Spinner";
import { Navigate } from "react-router-dom";

export const UserContext = createContext(null);

// Check if a user exists, if so display children, else navigate to login page
const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isValidUser, setIsValidUser] = useState(null);

  const validateUser = async (user) => {
    await fetch(process.env.REACT_APP_API_URL + "v1/verify_id_token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idToken: await user.getIdToken(),
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          console.log("Error in validating id token");
          console.log(await response.json());
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(async (data) => {
        console.log("response is", data);
        setIsValidUser(true);
      })
      .catch((error) => {
        setIsValidUser(false);
        console.error(
          "There was a problem with the fetch operation with verifying id token:",
          error
        );
      });
  };

  useEffect(() => {
    const unsubscribe = FIREBASE_AUTH_HERMES.onAuthStateChanged(
      async (tmpUser) => {
        if (tmpUser) {
          setUser(tmpUser);
          await validateUser(tmpUser);
        } else {
          setUser(null);
          setIsValidUser(false);
        }
        setIsLoading(false);
      }
    );
    return () => unsubscribe();
  }, []);
  return (
    <UserContext.Provider value={{ user, setUser, isValidUser }}>
      {isLoading ? <Spinner /> : children}
    </UserContext.Provider>
  );
};

export default UserProvider;
