import React, { useState } from "react";
import MoCoModal from "./MoCoModal";

const MoCoCoupon = ({ coupon, index, enableTouch }) => {
  const [openModal, setOpenModal] = useState(false);
  const handleOnClick = () => {
    if (!enableTouch) return;
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <MoCoModal show={openModal} onClose={handleClose}>
        <div class="flex flex-1 flex-col ">
          <h2 className="text-2xl font-bold underline">Coupon Details</h2>
          <br />
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex flex-1 border-r-0 md:border-r items-center justify-center">
              <img
                src={coupon.url}
                className="w-[128px] aspect-square	object-cover rounded-full"
              />
            </div>
            <div className="flex flex-1  items-center justify-center">
              <h2 className="text-2xl">{coupon.name}</h2>
            </div>
          </div>
          <div className="my-4">
            <h2 className="text-xl font-bold ">Business Name</h2>
            <h2 className="text-xl">{coupon.businessName}</h2>
            <br />
            <h2 className="text-xl font-bold ">Description</h2>
            <h2 className="text-lg text-[#636363]">
              {coupon.description ? coupon.description : "N/A"}
            </h2>
          </div>
          <div></div>
        </div>
      </MoCoModal>
      <div
        onClick={handleOnClick}
        className="shadow-xl w-[350px] h-[125px] flex flex-row"
        style={{
          cursor: enableTouch ? "pointer" : "auto",
        }}
      >
        <div
          className="flex basis-[28.57142%] bg-red-100 justify-center items-center"
          style={{ backgroundColor: index % 2 == 0 ? "#941C2F" : "#2A324B" }}
        >
          <img
            src={coupon.url}
            className="rounded-full w-[60px] h-[60px] object-cover"
          ></img>
        </div>
        <div className="flex basis-[71.42858%] bg-[#343434] flex-col p-2">
          <div className="flex basis-1/4  items-center">
            <h2 className="font-bold text-xl text-[#fefefe] coupon-name">
              {coupon.name}
            </h2>
          </div>
          <div className="flex basis-2/4  items-center ">
            <h2 className="text-md text-[#fefefe] coupon-description">
              {coupon.description}
            </h2>
          </div>
          <div className="flex basis-1/4  items-center">
            <h2 className="text-sm text-[#fefefe] coupon-business-name">
              {coupon.businessName}
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default MoCoCoupon;
