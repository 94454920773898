export const CONSUMER_APP_STORE_LINK =
  "https://apps.apple.com/us/app/campfire-loyalty/id6478505143?uo=2";
export const CONSUMER_PLAY_STORE_LINK =
  "https://play.google.com/store/apps/details?id=com.campfireloyalty";
export const BUSINESS_APP_STORE_LINK =
  "https://apps.apple.com/us/app/campfire-business/id6479206607?uo=2";
export const BUSINESS_PLAY_STORE_LINK =
  "https://play.google.com/store/apps/details?id=com.punchbusiness";
export const MOCO_CALENDLY_LINK =
  "https://calendly.com/campfireloyalty/meeting-w-campfire-can-be-longer-than-30-min?month=2024-06";
export const MOCO_GOOGLE_FORM_LINK =
  "https://docs.google.com/forms/d/e/1FAIpQLSd6g84RNH4f9vic6Bl56J57_BbglnjvTSm5KQTYBPOLwJeA-Q/viewform?embedded=true";
export const DOWNLOAD_LINK = "https://tinyurl.com/2akq36hm";
export const DOWNLOAD_LINK_FAST =
  "https://campfireloyalty.com/download/redirect/consumer/general";
export const environment_ =
  process.env.NODE_ENV === "development" ? "beta" : "prod";
export const DEBOUNCE_MILLISECONDS = 600;
export const START_FETCHING_HEIGHT = 0.8;
export const CATEGORIES = [
  { id: 1, text: "antique store", checked: false },
  { id: 2, text: "auto repair", checked: false },
  { id: 3, text: "bakery", checked: false },
  { id: 4, text: "bar", checked: false },
  { id: 39, text: "barber shop", checked: false },
  { id: 5, text: "beauty salon", checked: false },
  { id: 6, text: "brewpub", checked: false },
  { id: 7, text: "cafe", checked: false },
  { id: 8, text: "cannabis store", checked: false },
  { id: 9, text: "clothing store", checked: false },
  { id: 10, text: "dry cleaner", checked: false },
  { id: 11, text: "embroidery store", checked: false },
  { id: 12, text: "escape room", checked: false },
  { id: 13, text: "electronics store", checked: false },
  { id: 14, text: "fishing store", checked: false },
  { id: 15, text: "fitness center", checked: false },
  { id: 16, text: "florist", checked: false },
  { id: 17, text: "furniture", checked: false },
  { id: 18, text: "gift store", checked: false },
  { id: 19, text: "gallery", checked: false },
  { id: 20, text: "grocery store", checked: false },
  { id: 21, text: "gym", checked: false },
  { id: 22, text: "hardware store", checked: false },
  { id: 23, text: "herb shop", checked: false },
  { id: 24, text: "hobby store", checked: false },
  { id: 25, text: "jewelry store", checked: false },
  { id: 26, text: "laundromat", checked: false },
  { id: 27, text: "liquor store", checked: false },
  { id: 28, text: "metaphysical store", checked: false },
  { id: 29, text: "pet store", checked: false },
  { id: 30, text: "pharmacy", checked: false },
  { id: 31, text: "piercing shop", checked: false },
  { id: 32, text: "restaurant", checked: false },
  { id: 33, text: "record store", checked: false },
  { id: 34, text: "sporting goods store", checked: false },
  { id: 35, text: "tattoo shop", checked: false },
  { id: 36, text: "theater", checked: false },
  { id: 37, text: "thrift store", checked: false },
  { id: 38, text: "vape store", checked: false },
];

export const DIETARY_RESTRICTIONS = [
  { id: 1, text: "halal", checked: false },
  { id: 2, text: "vegan", checked: false },
  { id: 3, text: "vegetarian", checked: false },
  { id: 4, text: "kosher", checked: false },
  { id: 5, text: "gluten-free", checked: false },
  { id: 6, text: "lactose-free/dairy-free", checked: false },
  { id: 7, text: "paleo", checked: false },
  { id: 8, text: "ketogenic", checked: false },
  { id: 9, text: "pescatarian", checked: false },
];
