import React, { useEffect, useState } from "react";
import NavBarMoco from "../components/NavBarMoco";
import Footer from "../components/Footer";
import LoadingIcon from "../components/LoadingIcon";
import MoCoCoupon from "../components/MoCoCoupon";
import { environment_ } from "../helpers/constants";
import Modal from "../components/Modal";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
  },
};
const MoCoAllCoupons = () => {
  const [coupons, setCoupons] = useState([]);
  const [couponsIsLoading, setCouponsIsLoading] = useState(true);

  useEffect(() => {
    document.title = "MoCoPass' Offers";
  }, []);
  // TODO: optimize with context provider / redux
  useEffect(() => {
    const fetchCoupons = async () => {
      await fetch(
        // process.env.REACT_APP_MOCO_API + environment_ + "/moco/coupons",
        process.env.REACT_APP_MOCO_API + environment_ + "/moco/coupons",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_MOCO_RL_API_KEY,
          },
        }
      )
        .then(async (response) => {
          if (!response.ok) {
            console.log(await response.json());

            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Fetched Coupons are is ", data);
          setCoupons(data["coupons"]);
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        })
        .finally(() => {
          setCouponsIsLoading(false);
        });
    };
    fetchCoupons();
  }, []);

  return (
    <div
      className="bg-[#feffff] "
      style={{ overflowX: "hidden", overflowY: "auto" }}
    >
      <NavBarMoco />

      <div className="mt-[7.5vh] sm:mt-[10vh] md:mt-[12.5vh]  w-full justify-center items-center">
        {couponsIsLoading ? (
          <LoadingIcon />
        ) : (
          <div className="flex  p-16 flex-wrap justify-center items-center ">
            <h2 className="text-4xl text-center">{coupons.length} offers</h2>
            <div className="flex flex-row p-16 flex-wrap justify-center items-center ">
              {coupons.map((item, index) => {
                return (
                  <div className="m-8 ">
                    <MoCoCoupon
                      coupon={item}
                      index={index}
                      enableTouch={true}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default MoCoAllCoupons;
