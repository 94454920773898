import React, { useEffect, useState } from "react";
import { getMobileOperatingSystem } from "../helpers/helper";
import {
  CONSUMER_APP_STORE_LINK,
  CONSUMER_PLAY_STORE_LINK,
} from "../helpers/constants";

const DownloadRedirectConsumer_v1 = () => {
  const [OS, setOS] = useState(getMobileOperatingSystem());
  const [finished, setFinished] = useState(false);
  // const ASD;
  // first search for the business name

  const getQueryParam = (name) => {
    const searchParams = new URLSearchParams(window.location.search);
    const item_ = searchParams.get(name);
    return item_ ? item_ : "";
  };

  useEffect(() => {
    // local storage stuff here
    const execute = async () => {
      // Send a request to the database to add the item

      await fetch(process.env.REACT_APP_VISITED_LINK, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          businessName: getQueryParam("businessName"),
          OS: OS.toLowerCase(),
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            console.log(await response.json());
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        })
        .finally(() => {
          setFinished(true);
        });
    };
    if (!localStorage.getItem(getQueryParam("businessName"))) {
      localStorage.setItem(getQueryParam("businessName"), "true");
      execute();
    } else {
      setFinished(true);
    }
  }, []);

  useEffect(() => {
    if (finished) {
      if (OS == "iOS") {
        // Go to app store
        window.location.href = CONSUMER_APP_STORE_LINK;
      } else {
        // Go to play store
        window.location.href = CONSUMER_PLAY_STORE_LINK;
      }
    }
  }, [finished]);
  return <div className=""></div>;
};

export default DownloadRedirectConsumer_v1;
