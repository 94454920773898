import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { RxCheck } from "react-icons/rx";
import { FIREBASE_APP, FIREBASE_AUTH } from "../FirebaseConfig";
const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const [valid, setValid] = useState(null);
  const [oobCode, setOobCode] = useState("");
  const [password, setPassword] = useState("");
  const paraOobCode = searchParams.get("oobCode");
  const paraMode = searchParams.get("mode");
  const [mode, setMode] = useState("");
  const [email, setEmail] = useState("");

  const [visible, setVisible] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(null);

  const [is12letter, setIs12Letter] = useState(false);
  const [hasOneSpecial, setHasOneSpecial] = useState(false);
  const [hasOneCapital, setHasOneCapital] = useState(false);
  const [hasOneLower, setHasOneLower] = useState(false);
  const [hasOneNumber, setHasOneNumber] = useState(false);

  useEffect(() => {
    try {
      if (!oobCode) return;

      if (mode == "verifyEmail") {
        setTimeout(() => {
          window.location.href =
            `https://campfire-8a814.firebaseapp.com/__/auth/action` +
            window.location.search;
        }, 1000);
        window.location.href = `campfireloyalty://verifyEmail/${oobCode}`;

        return;
      }
      FIREBASE_AUTH.verifyPasswordResetCode(oobCode)
        .then((email) => {
          setEmail(email);
          setValid(true);
        })
        .catch((err) => {
          // Invalid code
          setValid(false);
        });
    } catch (err) {
      console.log("Some error happened", err);
      setValid(false);
    }
  }, [oobCode, mode]);

  useEffect(() => {
    if (paraOobCode) {
      console.log("Parcode setting", paraOobCode);
      setOobCode(paraOobCode);
    }
    if (paraMode) {
      console.log("paraMode setting", paraMode);
      setMode(paraMode);
    }
  }, [searchParams]);

  function validateAndSetPassword(event) {
    event.preventDefault();
    const text = event.target.value;
    text.length >= 12 ? setIs12Letter(true) : setIs12Letter(false);
    /[a-z]/.test(text) ? setHasOneLower(true) : setHasOneLower(false);
    /[A-Z]/.test(text) ? setHasOneCapital(true) : setHasOneCapital(false);
    /[0-9]/.test(text) ? setHasOneNumber(true) : setHasOneNumber(false);
    /[^a-zA-Z0-9]/.test(text)
      ? setHasOneSpecial(true)
      : setHasOneSpecial(false);

    setPassword(text);
  }

  function validPassword() {
    return (
      is12letter &&
      hasOneCapital &&
      hasOneLower &&
      hasOneSpecial &&
      hasOneNumber
    );
  }
  async function handleSubmit() {
    if (!validPassword()) {
      return;
    }

    FIREBASE_AUTH.confirmPasswordReset(oobCode, password)
      .then(() => {
        console.log("Password resetted successfully");
        setResetSuccess(true);
        setTimeout(() => {
          window.location.href = `campfireloyalty://`;
        }, 1000);
      })
      .catch((err) => {
        console.log("some error happened");
        setResetSuccess(false);
      });

    // do some submission here
  }

  return (
    <>
      {valid == null ? (
        <div>Loading...</div>
      ) : valid == true ? (
        <div className="flex justify-center p-4 ">
          <div className="flex  flex-col justify-around items-center border border-4 p-4 rounded-xl min-h-[30vh]  shadow-xl   ">
            {resetSuccess == null ? (
              <>
                <div>
                  <h2 className="text-xl">Let's get you back on Campfire 🔥</h2>
                  <h3 className="text-md">
                    Reset password for : <b>{email}</b>
                  </h3>
                </div>
                <div className="my-4 items-center justify-center w-full flex border bg-[#FEFFFF]  border-2	rounded-xl	md-max:text-xl text-md py-2 px-4 font-bold transition">
                  <input
                    type={visible ? "text" : "password"}
                    placeholder="Type your new password..."
                    className="outline-0 w-full mr-2"
                    value={password}
                    onChange={(event) => {
                      validateAndSetPassword(event);
                    }}
                  ></input>
                  <button
                    onClick={() => {
                      setVisible((val) => !val);
                    }}
                  >
                    {visible ? <FaEye /> : <FaEyeSlash />}
                  </button>
                </div>

                <div className="my-4">
                  <div className="flex items-center ">
                    {is12letter ? (
                      <RxCheck className="text-[#00CC00]" />
                    ) : (
                      <RxCross2 className="text-[#FF0000]" />
                    )}
                    <p style={{ color: is12letter ? "#00CC00" : "#FF0000" }}>
                      At least 12 letters
                    </p>
                  </div>
                  <div className="flex items-center ">
                    {hasOneSpecial ? (
                      <RxCheck className="text-[#00CC00]" />
                    ) : (
                      <RxCross2 className="text-[#FF0000]" />
                    )}
                    <p style={{ color: hasOneSpecial ? "#00CC00" : "#FF0000" }}>
                      At least 1 special character
                    </p>
                  </div>
                  <div className="flex items-center ">
                    {hasOneLower ? (
                      <RxCheck className="text-[#00CC00]" />
                    ) : (
                      <RxCross2 className="text-[#FF0000]" />
                    )}
                    <p style={{ color: hasOneLower ? "#00CC00" : "#FF0000" }}>
                      At least 1 lowercase letter
                    </p>
                  </div>
                  <div className="flex items-center ">
                    {hasOneCapital ? (
                      <RxCheck className="text-[#00CC00]" />
                    ) : (
                      <RxCross2 className="text-[#FF0000]" />
                    )}
                    <p style={{ color: hasOneCapital ? "#00CC00" : "#FF0000" }}>
                      At least 1 capital letter
                    </p>
                  </div>
                  <div className="flex items-center ">
                    {hasOneNumber ? (
                      <RxCheck className="text-[#00CC00]" />
                    ) : (
                      <RxCross2 className="text-[#FF0000]" />
                    )}
                    <p style={{ color: hasOneNumber ? "#00CC00" : "#FF0000" }}>
                      At least 1 number
                    </p>
                  </div>
                </div>

                <div className="flex self-end">
                  <button
                    disabled={!validPassword()}
                    onClick={handleSubmit}
                    className="px-2 py-1 bg-[#FFC288]   border-2 rounded-md"
                    onMouseEnter={(e) => {
                      if (!validPassword()) return;
                      e.target.style.color = "#FEFFFF";
                      e.target.style.backgroundColor = "#FF6701";
                    }}
                    onMouseLeave={(e) => {
                      if (!validPassword()) return;
                      e.target.style.color = "#000000";
                      e.target.style.backgroundColor = "#FFC288";
                    }}
                    style={{ opacity: validPassword() ? 1 : 0.5 }}
                  >
                    Save
                  </button>
                </div>
              </>
            ) : resetSuccess == true ? (
              <p className="text-xl text-[#00CC00]">
                Password reset succesfully! You may now login with the new
                password!
              </p>
            ) : (
              <p className="text-xl text-[#FF0000]">
                Something went wrong! Please retry again. If it still doesn't
                work, please email us at campfireloyalty@gmail.com and we will
                get back to you!
              </p>
            )}
          </div>
        </div>
      ) : (
        <div>Invalid request...</div>
      )}
    </>
  );
};

export default ResetPassword;
