import React from "react";
import NavBar from "../components/NavBar";

const NoPage = () => {
  return (
    <div>
      <h2>This page doesn't exist</h2>
    </div>
  );
};

export default NoPage;
