import React from "react";
import FAQ from "./FAQ";

const FAQs = ({ FAQsRef }) => {
  return (
    <div
      ref={FAQsRef}
      className="px-[10vw] py-[7em] flex justify-center items-center flex-col"
    >
      <h1 className="text-5xl font-bold p-[2em]">FAQs</h1>
      <FAQ
        question={"- What kind of business can join Campfire?"}
        answer={
          "Small businesses in the retail/restaurant category are welcome to join us. "
        }
      ></FAQ>
      <FAQ
        question={"- How much does Campfire charge?"}
        answer={
          "Currently, we are only looking for people to test out the idea, so joining and testing out the program is completely free, with no catch."
        }
      ></FAQ>
      <FAQ
        question={"- How do I join the program?"}
        answer={
          "Email campfireloyalty@gmail.com or give us a call at (248) 660-9169, and we will get back to you as soon as possible."
        }
      ></FAQ>
      <FAQ
        question={"- In what location is Campfire available right now?"}
        answer={
          "Currently, we are only available in Ypsilanti, Michigan and Montgomery County, Maryland. Join our waitlist if you want us to be in your city!"
        }
      ></FAQ>
    </div>
  );
};

export default FAQs;
