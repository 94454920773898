import React from "react";
import FAQ from "./FAQ";

const MoCo_FAQs = ({ FAQsRef }) => {
  return (
    <div
      ref={FAQsRef}
      className="px-[10vw] pb-[7em] flex justify-center items-center flex-col"
    >
      <h1 className="text-5xl font-bold p-[2em]">FAQs</h1>
      {/* <FAQ
        question={"- How do I purchase the pass?"}
        answer={
          "You can download the app and subscribe to the MoCoPass for $4.99/month, you can cancel at any time. Once subscribed, you unlock all offers in the pass."
        }
      /> */}
      {/* <FAQ
        question={"- What offers are currently available?"}
        answer={"You can see all offers on the top."}
      /> */}
      {/* <FAQ
        question={"- I am a business owner, how do I join?"}
        answer={
          "If you want to join us, please fill up this form and we will get back to you."
        }
      /> */}
      <FAQ
        question={"- What do the business owners get out of it?"}
        answer={
          "MoCoPass encourages customers to spend more at participating businesses, so we boost restaurants' sales and revenue"
        }
      />
      <FAQ
        question={
          "- What if a business owner add/remove a coupon after I subscribed?"
        }
        answer={
          "If a business owner add a new coupon after you subscribed, you can use the coupon immediately. If a business owner removes a coupon after you subscribed, then you won't be able to use it. However, you will be able to use the coupon before they removed it."
        }
      />
      <FAQ
        question={"- Will I be charged again after I subscribed?"}
        answer={
          "By default, you will have auto-billing turn on, meaning you will be charged $4.99 again exactly 30 days after you subscribed. However, feel free to cancel at any time."
        }
      />
      <FAQ
        question={"- How many times can I use the offers in the pass?"}
        answer={
          "You can use the offer once per subscription cycle. When you resubscribe, you will be able to use the offers again, even if you used it in the last subscription cycle."
        }
      />
      <FAQ
        question={"- What payment methods are accepted?"}
        answer={"We accept card, Google Pay, Apple Pay, and Cash App Pay."}
      />
      <FAQ
        question={"- Who or what is Campfire?"}
        answer={
          "Campfire is a startup based in Ypsilanti, Michigan, founded by 3 University of Michigan's students. Their goal is to help small businesses grow and scale by combining them together into one gigantic loyalty program. The app allows you to upload any receipt (online, physical, etc) from any platform and receive points per dollar spent, these points can then be used to redeem coupons put up by the business owners."
        }
      />
    </div>
  );
};

export default MoCo_FAQs;
