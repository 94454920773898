import React from "react";
import "../LoadingIcon.css"; // Import your CSS file for styling

const LoadingIcon = () => {
  return (
    <div className="loading-container">
      <div className="loading-icon"></div>
    </div>
  );
};

export default LoadingIcon;
