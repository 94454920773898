import React, { useState } from "react";
import * as emailjs from "emailjs-com";
import Swal from "sweetalert2";

const SignUpForm = ({ signUpRef }) => {
  const [formData, setFormdata] = useState({
    email: "",
    name: "",
    message: "",
    loading: false,
    show: false,
    alertmessage: "",
  }); //

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormdata({ loading: true });

    const templateParams = {
      from_name: formData.email,
      user_name: formData.name,
      message: formData.message,
    };

    const message = await new Promise((resolve, reject) => {
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          templateParams,
          process.env.REACT_APP_EMAILJS_USER_ID
        )
        .then(
          (result) => {
            setFormdata({
              email: "",
              name: "",
              message: "",
            });
            console.log("SUCCESS");
            resolve(result);
          },
          (err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong! Please try again",
            });
            console.log(err);
            reject(err);
            return;
          }
        );
    });

    Swal.fire({
      title: "SUCCESS!",
      text: "We will follow up with an email!",
      icon: "success",
    });
  };

  const handleChange = (e) => {
    setFormdata({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div
      ref={signUpRef}
      className="z-10 relative h-fit bg-[#0e1111] flex md-max:flex-col  justify-center items-center py-16 px-8 "
    >
      <div className="flex flex-col md-max:mb-8 md-max:w-[100%] w-[40%] justify-center align-center text-center ">
        <h2 className="text-[#FEFFFF] text-4xl">
          Join Campfire Today <b>For Free</b>
        </h2>
        {/* <h2 className="text-[#FEFFFF] text-3xl">
          <b>only $50 / month</b>
        </h2> */}
      </div>
      <div className="flex w-[60%] md-max:w-[100%]  justify-center align-center  ">
        <form
          onSubmit={handleSubmit}
          className=" flex flex-col w-[80%] justify-center items-center"
        >
          <h3 className="text-[#FEFFFF] text-2xl p-4">Sign up Now!</h3>

          <div className="flex flex-row w-full">
            <input
              id="name"
              name="name"
              placeholder="Name"
              value={formData.name || ""}
              type="text"
              required
              onChange={handleChange}
              className=" w-full mr-2 flex p-2 rounded-sm text-[#0e1111] placeholder-[#f746D69]"
            />
            <input
              id="email"
              name="email"
              placeholder="Email"
              type="email"
              value={formData.email || ""}
              required
              onChange={handleChange}
              className=" w-full ml-2 flex p-2 rounded-sm text-[#0e1111] placeholder-[#f746D69]"
            />
          </div>
          <textarea
            id="message"
            name="message"
            placeholder="Message"
            rows="5"
            value={formData.message}
            onChange={handleChange}
            required
            className="w-full h-[80%]  m-2  p-2 rounded-sm text-[#0e1111] placeholder-[#f746D69] "
          ></textarea>
          <br />
          <button
            className="py-4 px-8 hover:text-[#FEFFFF] hover:bg-[#FF914D] transition ease-in-out delay-50  rounded-sm bg-[#FEFFFF] text-[#0e1111] text-lg font-bold "
            type="submit"
          >
            {formData.loading ? "Sending..." : "Send"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignUpForm;
