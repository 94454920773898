import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "./context/UserProvider";
import { FIREBASE_AUTH_HERMES } from "./FirebaseConfig";
import Spinner from "./components/Spinner";

const DisplayProvider = ({ isValidUser, children }) => {
  const getStrategy = () => {
    switch (isValidUser) {
      case null:
        return <Spinner />;
      case true:
        return children;
      default:
        return <Navigate to="/hermes/login" />;
    }
  };

  return <>{getStrategy()}</>;
};
const HermesRoute = ({ children }) => {
  // const [isAuthenticated, setIsAuthenticated] = useState(true);
  const { isValidUser } = useContext(UserContext);

  useEffect(() => {
    document.title = "Hermes";
  }, []);
  return <DisplayProvider isValidUser={isValidUser} children={children} />;
};

export default HermesRoute;
