import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import MoCoCoupon from "./MoCoCoupon";

const CouponCarousel = ({ coupons, couponsIsLoading }) => {
  console.log("couponsIsLoading", couponsIsLoading);
  console.log("coupons", coupons);

  return (
    <>
      {!couponsIsLoading && (
        <div class="wrapper">
          <div class="marquee">
            <div class="marquee__group">
              {coupons.map((item, index) => (
                <MoCoCoupon coupon={item} index={index} />
              ))}
            </div>
            <div aria-hidden="true" class="marquee__group">
              {coupons.map((item, index) => (
                <MoCoCoupon coupon={item} index={index} />
              ))}
            </div>
          </div>
          <div class="marquee marquee--reverse">
            <div class="marquee__group">
              {coupons.map((item, index) => (
                <MoCoCoupon coupon={item} index={index} />
              ))}
            </div>
            <div aria-hidden="true" class="marquee__group">
              {coupons.map((item, index) => (
                <MoCoCoupon coupon={item} index={index} />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CouponCarousel;
