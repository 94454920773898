import React, { useState } from "react";

const mountedStyle = { animation: "slideInFade 350ms ease-in" };
const unmountedStyle = {
  animation: "slideOutFade 350ms ease-out",
  animationFillMode: "forwards",
};

const mountedStyleLine = { animation: "slideIn 350ms ease-in" };
const unmountedStyleLine = {
  animation: "slideOut 350ms ease-out",
  animationFillMode: "forwards",
};

const FAQ = ({ question, answer }) => {
  const [open, setOpen] = useState(false);

  return (
    <button
      onClick={() => {
        setOpen((val) => !val);
      }}
      className={`cursor-pointer flex w-full flex-col p-[1em] `}
    >
      <div className="flex flex-row w-full justify-between ">
        <h2 className="text-xl">{question}</h2>
        <h2 className="text-3xl">{open ? "-" : "+"} </h2>
      </div>
      <div
        style={open ? mountedStyle : unmountedStyle}
        className={` text-lg py-[1em] w-full text-left  text-gray-500
          `}
      >
        {answer}
      </div>
      <div
        style={open ? mountedStyle : unmountedStyle}
        className="w-full border-b border-gray-400"
      ></div>
    </button>
  );
};

export default FAQ;
