import React from "react";
import { BiSolidDiscount } from "react-icons/bi";
import { GiAlliedStar, GiReceiveMoney } from "react-icons/gi";

const SectionTwo = ({ howItWorksRef }) => {
  return (
    <div
      ref={howItWorksRef}
      className="w-[100%]  flex flex-col items-center px-[10vw] py-[7em] h-fit"
    >
      <div className="mb-16 justify-center items-center h-[20%] text-center">
        <h2 className="text-5xl font-bold ">How it works</h2>
      </div>
      <div className="w-[40%] sm-max:w-[80%] items-center flex flex-col  mt-[5em]">
        <img
          alt="Customer purchasing item.png"
          src="images/purchase.png"
          className="w-[30%] h-[30%] sm:w-[40%] sm:h-[40%]  min-w-[350px] aspect-square"
        ></img>
        <h2 className="text-[1.5em] pt-4">
          1. A customer makes a purchase at your store, either online or
          in-store
        </h2>
      </div>
      <div className="w-[40%] sm-max:w-[80%] items-center  flex flex-col  mt-[5em]">
        <img
          alt="Customer receiving points.png"
          src="images/upload_receipt.gif"
          className="w-[30%] h-[30%] sm:w-[40%] sm:h-[40%]  min-w-[350px] aspect-square"
        ></img>
        <h2 className="text-[1.5em] pt-4">
          2. The customer then uploads their physical/online receipt into the
          Campfire App, and we give them 1 point per dollar spent
        </h2>
      </div>
      <div className="w-[40%] sm-max:w-[80%] items-center justify-center flex flex-col  mt-[5em]">
        <img
          alt="Using phone to buy item.png"
          src="images/phone.png"
          width={"30%"}
          className="min-w-[300px] sm:w-[30%] sm:h-[40%] sm:min-w-[350px] "
        ></img>
        <h2 className="text-[1.5em] pt-4">
          3. Finally, the customer purchases coupons from any store in our
          system (including yours)!
        </h2>
      </div>

      {/* <div className="justify-between  flex md-max:flex-col  flex-row items-center text-center h-fit">
        <div className="md-max:mb-8 justify-start items-center  min-h-[65%] flex flex-col md-max:w-[80%] md-max:w-[80%] w-[30%] ">
          <GiReceiveMoney size={160} className="mb-8" />

          <h5 className="text-2xl ">
            Customers purchase items at your business
          </h5>
        </div>
        <div className="md-max:mb-24 md-max:mt-16 md-max:items-center justify-start items-start h-[35%]  flex flex-col md-max:w-[100%]  w-[10%] ">
          <img
            src="/images/arrow1.png"
            alt="logo_text.png"
            className="h-[50px] md-max:h-[30px]  md-max:rotate-90"
          ></img>
        </div>
        <div className="md-max:mb-8 justify-start items-center min-h-[65%] flex flex-col md-max:w-[80%] w-[30%]">
          <GiAlliedStar size={160} className="mb-8" />
          <h5 className="text-2xl ">
            They receive loyalty points based on how much they pay
          </h5>
        </div>
        <div className="md-max:mb-24 md-max:mt-16 md-max:items-center justify-start items-start h-[35%] flex flex-col md-max:w-[100%] w-[10%] ">
          <img
            src="/images/arrow2.png"
            alt="logo_text.png"
            className="h-[50px] md-max:h-[30px] md-max:rotate-90"
          ></img>
        </div>
        <div className="md-max:mb-8 justify-start  min-h-[65%] items-center flex flex-col md-max:w-[80%] w-[30%]">
          <BiSolidDiscount size={160} className="mb-8" />
          <h5 className="text-2xl ">
            They buy coupons from any business in the system using the loyalty
            points
          </h5>
        </div>
      </div> */}
    </div>
  );
};

export default SectionTwo;
