import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "./context/UserProvider";
import { isAdmin } from "./helpers/helper";

const DisplayProvider = ({ email, children }) => {
  const getStrategy = () => {
    switch (isAdmin(email)) {
      case true:
        return children;
      case false:
        return <Navigate to="/hermes/no-access" replace />;
      default:
        return null;
    }
  };

  return <>{getStrategy()}</>;
};
const HermesAdminRoute = ({ children }) => {
  // const [isAuthenticated, setIsAuthenticated] = useState(true);
  const { user } = useContext(UserContext);

  useEffect(() => {
    document.title = "Hermes";
  }, []);

  return <DisplayProvider email={user.email} children={children} />;
};

export default HermesAdminRoute;
