import React, { useEffect, useState } from "react";
import { getMobileOperatingSystem } from "../helpers/helper";
import {
  CONSUMER_APP_STORE_LINK,
  CONSUMER_PLAY_STORE_LINK,
} from "../helpers/constants";

const DownloadRedirectConsumer = () => {
  const [OS, setOS] = useState(getMobileOperatingSystem());

  useEffect(() => {
    if (OS == "iOS") {
      // Go to app store
      window.location.href = CONSUMER_APP_STORE_LINK;
    } else {
      // Go to play store
      window.location.href = CONSUMER_PLAY_STORE_LINK;
    }
  }, []);
  return <div className="">Get freebies!</div>;
};

export default DownloadRedirectConsumer;
