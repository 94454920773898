import React, { useState } from "react";
import MoCoModal from "./MoCoModal";
import { formatISO8601Date } from "../helpers/timeHelper";

const Coupon = ({ coupon, enableTouch }) => {
  const [openModal, setOpenModal] = useState(false);
  const handleOnClick = () => {
    if (!enableTouch) return;
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <>
      <MoCoModal show={openModal} onClose={handleClose}>
        <div class="flex flex-1 flex-col ">
          <h2 className="text-2xl font-bold underline">Coupon Details</h2>
          <br />
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex flex-1 border-r-0 md:border-r items-center justify-center">
              <img
                src={coupon.url}
                className="w-[128px] aspect-square	object-cover rounded-full"
              />
            </div>
            <div className="flex flex-1  items-center justify-center">
              <h2 className="text-2xl">{coupon.name}</h2>
            </div>
          </div>
          <div className="my-4">
            <h2 className="text-xl font-bold ">Business Name</h2>
            <h2 className="text-xl">{coupon.businessName}</h2>
            <br />

            <h2 className="text-xl font-bold ">Description</h2>
            <h2 className="text-lg text-[#636363]">
              {coupon.description ? coupon.description : "N/A"}
            </h2>
            <br />
            <div className="flex flex-row  items-center w-full">
              <div className="flex flex-row items-center">
                <h2 className="text-lg text-[#140901] font-bold">
                  {coupon.price}
                </h2>
                <img
                  src="../images/fire_icon.png"
                  alt="point.png"
                  className="w-[22px] h-[22px]"
                />
              </div>
              <div className="pl-4">
                <h2 className="text-lg text-[#636363]">
                  {coupon.amount_left} left
                </h2>
              </div>
            </div>

            <div>
              <h2 className="text-md text-[#140901] text-[#636363] ">
                Expring on {formatISO8601Date(coupon.expirationDate)}
              </h2>
            </div>
          </div>
          <div></div>
        </div>
      </MoCoModal>
      <div
        onClick={handleOnClick}
        style={{
          cursor: enableTouch ? "pointer" : "auto",
        }}
        className="w-[350px] h-[125px] flex flex-row shadow-xl"
      >
        <div
          className="flex basis-[28.57142%] bg-red-100 justify-center items-center border-r"
          style={{ backgroundColor: "#FFFFFD" }}
        >
          <img
            src={coupon.url}
            className="rounded-full w-[60px] h-[60px] object-cover"
          ></img>
        </div>
        <div className="flex basis-[71.42858%] bg-[#FFFFFD] flex-col p-2">
          <div className="flex basis-1/4 justify-between overflow-hidden	 items-center">
            <div className="flex basis-3/4">
              <h2 className="font-bold text-lg text-[#140901] coupon-name">
                {coupon.name}
              </h2>
            </div>
            <div className="flex basis-1/4 overflow-hidden	 flex-row justify-end">
              {coupon.price == 0 ? (
                <h2 className="text-lg text-[#140901] coupon-name">FREE!</h2>
              ) : (
                <>
                  <h2 className="text-lg text-[#140901] coupon-name">
                    {coupon.price}
                  </h2>
                  <img
                    src="../images/fire_icon.png"
                    alt="point.png"
                    className="w-[22px] h-[22px]"
                  />
                </>
              )}
            </div>
          </div>
          <div className="flex basis-2/4  items-center ">
            <h2 className="text-md text-[#140901] coupon-description break-all	">
              {coupon.description}
            </h2>
          </div>
          <div className="flex basis-1/4 justify-between items-center">
            <h2 className="text-sm text-[#808080] coupon-business-name flex basis-[72.5%]">
              {coupon.businessName}
            </h2>
            <h2 className="text-sm text-[#808080] coupon-business-name flex text-right basis-[27.5%] ">
              {coupon.amount_left} left
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default Coupon;
