import React from "react";
import { useNavigate } from "react-router-dom";
import { FIREBASE_AUTH_HERMES } from "../../FirebaseConfig";

const Hermes = () => {
  const navigate = useNavigate();
  return (
    <div class="flex flex-col  min-h-screen ">
      <div className="flex flex-col bg-gray-100 p-8">
        <h2 className="text-3xl underline">Hermes</h2>
        <h3 className="text-xl">
          Welcome to Hermes, Campfire's internal tool. To begin, choose an
          action from below.
        </h3>
      </div>
      <button
        onClick={() => {
          console.log("Signing out");
          FIREBASE_AUTH_HERMES.signOut();
        }}
        class="absolute top-12 right-12 bg-white p-[0.5em] border border-black rounded"
      >
        <h2>Sign out</h2>
      </button>
      <div className="flex flex-grow flex-row h-full w-full   self-center justify-center items-center">
        <button
          className="mx-16 rounded-b-xl rounded-t-md shadow-2xl border-r-[2px] border-r-[#FF840D]	 text-2xl py-[0.5em] bg-[#FFF5E1]  w-fit px-[1em] border-b-[4px] border-b-[#FF840D]  hover:bg-[#FFDBB1] transition  "
          onClick={() => {
            navigate(`/hermes/business`);
          }}
        >
          Business Dashboard
        </button>

        <button
          className="mx-16 rounded-b-xl rounded-t-md shadow-2xl border-r-[2px] border-r-[#FF840D]	 text-2xl py-[0.5em] bg-[#FFF5E1]  w-fit px-[1em] border-b-[4px] border-b-[#FF840D]  hover:bg-[#FFDBB1] transition  "
          onClick={() => {
            navigate(`/hermes/dashboard`);
          }}
        >
          Review Receipt
        </button>
      </div>
    </div>
  );
};

export default Hermes;
