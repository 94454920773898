import "./App.css";
import { useRef } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./stacks/Home";
import NoPage from "./stacks/NoPage";
import ResetPassword from "./stacks/ResetPassword";
import ResetPasswordBusiness from "./stacks/ResetPasswordBusiness";
import Support from "./stacks/Support";
import Download from "./stacks/Download";
import { Analytics } from "@vercel/analytics/react";
import DownloadRedirectConsumer from "./stacks/DownloadRedirectConsumer";
import DownloadRedirectConsumer_v1 from "./stacks/DownloadRedirectConsumer_v1";
import WaitlistPage from "./stacks/WaitlistPage";
import GoToApp from "./stacks/GoToApp";
import HermesLogin from "./stacks/hermes/HermesLogin";
import HermesNoAccess from "./stacks/hermes/HermesNoAccess";
import Hermes from "./stacks/hermes/Hermes";
import HermesDashboard from "./stacks/hermes/HermesDashboard";
import HermesRoute from "./HermesRoute";
import UserProvider from "./context/UserProvider";
import HermesReview from "./stacks/hermes/HermesReview";
import DownloadRedirectBusiness_v1 from "./stacks/DownloadRedirectBusiness_v1";
import MoCo from "./stacks/MoCo";
import MoCoAllCoupons from "./stacks/MoCoAllCoupons";
import HermesAdminRoute from "./HermesAdminRoute";
import HermesBusinessDashboard from "./stacks/hermes/business/HermesBusinessDashboard";
import HermesAddBusinessUser from "./stacks/hermes/business/HermesAddBusinessUser";
import HermesAddBusinessAndUser from "./stacks/hermes/business/HermesAddBusinessAndUser";
import MoCoCouponPage from "./stacks/MoCoCouponPage";
import CouponPage from "./stacks/CouponPage";
import MoCoPassTerms from "./stacks/MoCoPassTerms";
import MoCoPassPrivacy from "./stacks/MoCoPassPrivacy";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="*" element={<NoPage />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route path="/business/action" element={<ResetPasswordBusiness />} />
        <Route path="/waitlist" element={<WaitlistPage />} />
        <Route path="/support" element={<Support />} />
        {/* <Route path="/hermes" element={<Hermes />} /> */}
        <Route
          path="/hermes"
          element={
            <UserProvider>
              <HermesRoute>
                <Hermes />
              </HermesRoute>
            </UserProvider>
          }
        />
        <Route
          path="/hermes/dashboard"
          element={
            <UserProvider>
              <HermesRoute>
                <HermesAdminRoute>
                  <HermesDashboard />
                </HermesAdminRoute>
              </HermesRoute>
            </UserProvider>
          }
        />
        <Route
          path="/hermes/login"
          element={
            <UserProvider>
              <HermesLogin />
            </UserProvider>
          }
        />
        <Route
          path="/hermes/no-access"
          element={
            <UserProvider>
              <HermesRoute>
                <HermesNoAccess />
              </HermesRoute>
            </UserProvider>
          }
        />
        <Route
          path="/hermes/business"
          element={
            <UserProvider>
              <HermesRoute>
                <HermesBusinessDashboard />
              </HermesRoute>
            </UserProvider>
          }
        />
        <Route
          path="/hermes/business/add"
          element={
            <UserProvider>
              <HermesRoute>
                <HermesAddBusinessAndUser />
              </HermesRoute>
            </UserProvider>
          }
        />
        <Route
          path="/hermes/business/add-user"
          element={
            <UserProvider>
              <HermesRoute>
                <HermesAddBusinessUser />
              </HermesRoute>
            </UserProvider>
          }
        />
        <Route
          path="/hermes/:id"
          element={
            <UserProvider>
              <HermesRoute>
                <HermesAdminRoute>
                  <HermesReview />
                </HermesAdminRoute>
              </HermesRoute>
            </UserProvider>
          }
        />
        {/* <Route path="/download" element={<Download />} /> */}
        <Route path="/MoCo" element={<MoCo />} />
        <Route path="/MoCo/privacy" element={<MoCoPassPrivacy />} />
        <Route path="/MoCo/terms" element={<MoCoPassTerms />} />
        <Route path="/MoCo/offers" element={<MoCoAllCoupons />} />

        <Route
          path="/download/redirect/consumer/v1"
          element={<DownloadRedirectConsumer_v1 />}
        />
        <Route path="/coupon/:id" element={<CouponPage />} />
        <Route path="/MoCoCoupon/:id" element={<MoCoCouponPage />} />
        <Route
          path="/download/redirect/consumer/general"
          element={<DownloadRedirectConsumer />}
        />
        <Route
          path="/download/redirect/consumer/receipt"
          element={<DownloadRedirectConsumer />}
        />
        <Route
          path="/download/redirect/business/v1"
          element={<DownloadRedirectBusiness_v1 />}
        />
        {/* <Route path="/gotoapp" element={<GoToApp />} /> */}
      </Routes>
      <Analytics></Analytics>
    </Router>
  );
}

export default App;
