import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { environment_ } from "../helpers/constants";
import LoadingIcon from "../components/LoadingIcon";
import Coupon from "../components/Coupon";
import BusinessInfo from "../components/BusinessInfo";
import { WiDirectionRight } from "react-icons/wi";

const CouponPage = () => {
  const { id } = useParams();
  const [coupon, setCoupon] = useState({});
  const [business, setBusiness] = useState({});
  const [couponsIsLoading, setCouponsIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!id) return;
    console.log("Fetching coupon and business info...");
    const fetchCoupons = async () => {
      await fetch(
        process.env.REACT_APP_MOCO_API + environment_ + `/coupon/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_MOCO_RL_API_KEY,
          },
        }
      )
        .then(async (response) => {
          if (!response.ok) {
            const error = await response.json();
            console.log(error);
            setErrorMessage(error["error"]);
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Fetched", data);
          setCoupon(data["coupon"]);
          setBusiness(data["business"]);
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        })
        .finally(() => {
          setCouponsIsLoading(false);
        });
    };
    fetchCoupons();
  }, [id]);
  const navigate = useNavigate();

  return (
    <div>
      {couponsIsLoading ? (
        <LoadingIcon />
      ) : errorMessage ? (
        errorMessage
      ) : (
        <div
          className="bg-[#FFF5E1] min-h-screen flex flex-col p-8 items-center justify-between"
          style={{ overflowX: "hidden", overflowY: "auto" }}
        >
          <div>
            {/* <div
              onClick={() => {
                navigate("/moco/offers");
              }}
              className="flex flex-row py-1 px-4 rounded-xl shadow-xl bg-[#FFFFFD] w-fit items-center"
            >
              <h3>See all available coupons</h3>
              <WiDirectionRight size={35} />
            </div> */}

            <h2 className="text-lg">Coupon Details:</h2>
            <Coupon coupon={coupon} enableTouch={true} />
            <br />
            <h2 className="text-lg">More about the business</h2>
            <BusinessInfo business={business} />
          </div>
          <div className="pt-4">
            <p>
              All rights reserved &copy; Campfire Loyalty, LLC{" "}
              {new Date().getFullYear()}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CouponPage;
