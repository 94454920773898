import React from "react";
import { MdGroups } from "react-icons/md";
import { BsGraphUpArrow, BsCashCoin } from "react-icons/bs";

const SectionThree = () => {
  return (
    <div className="w-[100%] p-16 h-fit bg-[#FFDF9B] text-[#0e1111]">
      <div className="mb-16 justify-center items-center h-[20%] text-center">
        <h2 className="text-6xl font-bold color-[#FF914D] mb-3">
          Everybody loves discounts!
        </h2>
        <h2 className="md-max:text-4xl text-5xl  color-[#FF914D]">
          By using Campfire, you increase your:{" "}
        </h2>
      </div>
      <div className="justify-between   flex md-max:flex-col flex-row items-center text-center h-fit">
        <div className="md-max:mb-16 min-h-[75%]  justify-start items-center min-h-[65%] flex flex-col md-max:w-[80%] w-[30%] ">
          <MdGroups size={160} color="#0e1111" className="mb-8" />

          <ul className="list-disc text-xl text-left">
            <li>Customer Lifetime Value</li>
            <li>Customer Base </li>
            <li>Customer Satisfaction</li>
          </ul>
        </div>
        <div className="md-max:mb-16 min-h-[75%]   justify-start items-center min-h-[65%] flex flex-col md-max:w-[80%] w-[30%]">
          <BsGraphUpArrow size={160} color="#0e1111" className="mb-8" />

          <ul className="list-disc text-xl text-left">
            <li>Traction</li>
            <li>Growth</li>
            <li>Retention Rate</li>
            <li>Repeat Purchase Rate</li>
            <li>Word of Mouth</li>
          </ul>
        </div>
        <div className="md-max:mb-16 min-h-[75%]  justify-start  min-h-[65%] items-center flex flex-col md-max:w-[80%] w-[30%]">
          <BsCashCoin size={160} color="#0e1111" className="mb-8" />

          <ul className="list-disc text-xl text-left">
            <li>Sales</li>
            <li>Revenue & Income</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
