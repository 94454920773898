import React from "react";
import { RxCross2 } from "react-icons/rx";
import "./MoCoModal.css";

const MoCoModal = ({ show, onClose, children }) => {
  if (!show) {
    return null;
  }
  return (
    <div className="moco-modal-backdrop">
      <div className="moco-modal-content">
        <button className="moco-modal-close" onClick={onClose}>
          <RxCross2 size={30} color={"red"} />
        </button>
        {children}
      </div>
    </div>
  );
};

export default MoCoModal;
