/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

export function isFiveMinutesOld(isoDateStr, isoSubmittedDateStr) {
  if (isoSubmittedDateStr == isoDateStr) return true;
  const givenDate = new Date(isoDateStr);
  const now = new Date();
  const differenceInMs = now - givenDate;
  const fiveMinutesInMs = 5 * 60 * 1000;

  return differenceInMs >= fiveMinutesInMs;
}

/**
 *
 * @param {*} email - user email
 * @returns - True if a user is an admin
 *
 * @note - This is not optimal, in the future switch to doing
 *         everything server side
 */
export function isAdmin(email) {
  const adminList = [
    "mameehy@hotmail.com",
    "trshen@umich.edu",
    "jkapala715@gmail.com",
  ];

  return adminList.includes(email);
}
export function capitalizeWords(str) {
  return str
    .split(/([^a-zA-Z0-9]+)/)
    .map((word) =>
      word.match(/^[a-zA-Z0-9]/)
        ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        : word
    )
    .join("");
}
