import React, { useRef } from "react";
import logo from "../logo.svg";
import NavBar from "../components/NavBar";
import TextBox from "../components/TextBox";
import SignUpForm from "../components/SignUpForm";
import Banner from "../components/Banner";
import SectionOne from "../components/SectionOne";
import SectionTwo from "../components/SectionTwo";
import SectionThree from "../components/SectionThree";
import SectionFour from "../components/SectionFour";
import FAQs from "../components/FAQs";
import Waitlist from "../components/Waitlist";
import Footer from "../components/Footer";

const Home = () => {
  const signUpRef = useRef(null);
  const howItWorksRef = useRef(null);
  const FAQsRef = useRef(null);
  const waitlistRef = useRef(null);
  const downloadRef = useRef(null);
  return (
    <div
      className="bg-[#feffff] overflow-x-hidden "
      style={{ overflowX: "hidden", overflowY: "auto" }}
    >
      <NavBar
        signUpRef={signUpRef}
        howItWorksRef={howItWorksRef}
        FAQsRef={FAQsRef}
        waitlistRef={waitlistRef}
        downloadRef={downloadRef}
      ></NavBar>
      <Banner signUpRef={signUpRef} waitlistRef={waitlistRef}></Banner>
      <SectionOne></SectionOne>
      <SectionTwo howItWorksRef={howItWorksRef}></SectionTwo>
      <SectionThree></SectionThree>
      {/* <SectionFour></SectionFour> */}
      <Waitlist waitlistRef={waitlistRef}></Waitlist>
      <FAQs FAQsRef={FAQsRef}></FAQs>
      <SignUpForm signUpRef={signUpRef}></SignUpForm>
      <Footer downloadRef={downloadRef}></Footer>
    </div>
  );
};

export default Home;
