export function formatISO8601Date(isoDate) {
  return new Intl.DateTimeFormat("en-US", {
    timeZone: "America/New_York", // EST/EDT timezone
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  }).format(new Date(isoDate));
}
export function formatISO8601DateAgnostic(isoDate) {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  }).format(new Date(isoDate));
}

export function formatISO8601YearMonthDay(isoDate) {
  return new Intl.DateTimeFormat("en-US", {
    timeZone: "America/New_York", // EST/EDT timezone
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(new Date(isoDate));
}

export function formatISO8601HourMinuteSeconds(isoDate) {
  return new Intl.DateTimeFormat("en-US", {
    timeZone: "America/New_York", // EST/EDT timezone
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  }).format(new Date(isoDate));
}

export function convertISO8601ESTtoISO8601UTC(isoDate) {
  // Use Intl.DateTimeFormat instead
  let tempDate = isoDate;

  if (isoDate[isoDate.length - 1] != "Z") {
    tempDate += "Z";
  }
  const date = new Date(tempDate);

  const DSToffset = 300;
  const ESToffset = 240;

  const isDST = date.getTimezoneOffset() === DSToffset;
  const offset = isDST ? DSToffset : ESToffset;

  console.log("OFFSET IS", offset);
  date.setTime(date.getTime() + offset * 60 * 1000);
  return date.toISOString();
}
