import React from "react";
import { MdOutlinePhone } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { MdLocationOn } from "react-icons/md";

const BusinessInfo = ({ business }) => {
  console.log("businessbusiness", business);
  return (
    <div className="w-[350px] shadow-xl bg-[#FFFFFD]  rounded-lg flex flex-col justify-center items-center p-4">
      <div className="flex flex-row justify-between items-center  w-full pb-2">
        <div className="p-4 border-r flex basis-2/5	 min-w-[64px] min-h-[64px]  justify-center items-center">
          <img
            src={business.url}
            className="rounded-full w-[64px] h-[64px] object-cover "
          />
        </div>
        <div className="flex p-4   basis-3/5	 justify-center items-center text-xl font-bold">
          <h2>{business.name}</h2>
        </div>
      </div>
      <div className="self-start  w-full">
        <a
          href={`https://www.google.com/maps?q=${business.lat},${business.long}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="flex flex-col  justify-center">
            <div className="flex flex-row   items-center">
              <MdLocationOn />
              <h3 className="leading-6 text-lg">{business.address}</h3>
            </div>

            <h4 className="text-md text-gray-400 leading-6">
              {business.city}, {business.state}
            </h4>
          </div>
        </a>
        <div className="py-2">
          <h2 className="text-md break-words">{business.about}</h2>
        </div>
        <div className="flex flex-row items-center justify-between ">
          <a
            className="flex business-contact w-full"
            href={`tel:${business.phoneNumber}`}
          >
            <div className="flex 	  flex-row items-center">
              <MdOutlinePhone className="flex-shrink-0" />
              <h3 className="pl-2  coupon-business-name break-all ">
                {business.phoneNumber}
              </h3>
            </div>
          </a>
          <a
            className="business-contact"
            href={`mailto:${business.emailAddress}`}
          >
            <div className="flex  	 overflow-hidden  flex-row items-center ">
              <MdOutlineMail className="flex-shrink-0" />
              <h3 className="pl-2  coupon-business-name break-all">
                {business.emailAddress}
              </h3>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default BusinessInfo;
