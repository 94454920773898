import React from "react";
import NavBarMoco from "../components/NavBarMoco";
import Footer from "../components/Footer";

const MoCoPassPrivacy = () => {
  return (
    <div
      className="bg-[#feffff] "
      style={{ overflowX: "hidden", overflowY: "auto" }}
    >
      <NavBarMoco />
      <div className="p-8 mt-[7.5vh] sm:mt-[10vh] md:mt-[12.5vh]  w-full justify-center items-center">
        <h2 className="text-4xl font-bold leading-10">
          MoCoPass Privacy Policy
        </h2>
        <br />
        <p>
          <b>Effective Date:</b> 7/1/2024
        </p>
        <p>
          <b>Company</b> (“We”): Campfire Loyaltly LLC, 140 Linda Court
          Bloomfield Hills, MI 48304
        </p>
        <br />
        <h2 className="text-2xl font-bold leading-10">Introduction</h2>
        <p>
          Welcome to Campfire x MoCoShow. We are committed to protecting your
          personal information and your right to privacy. By purchasing
          MoCoPass, you consent to the methods and practices described in this
          document. If you have any questions or concerns about our policy or
          our practices with regard to your personal information, please contact
          us at campfireloyalty@gmail.com or +1248 (660) 9169.
        </p>

        <br />
        <h2 className="text-2xl font-bold leading-10">
          Information We Collect
        </h2>
        <p>
          When you use our services, we collect the following types of
          information:
        </p>
        <br />
        <p>
          <b>Subscription Information:</b>
          <br />
          <ul>
            <li>
              {" "}
              - Subscription Start Date: The date when your subscription begins.
            </li>
            <li>
              - Subscription End Date: The date when your subscription ends.
            </li>
            <li>
              - Subscription Cancellation Status: Indicates whether your
              subscription is active or canceled.
            </li>
            <li>- User Email: Your email address when you subscribed.</li>
            <li>
              - Coupons Used: Details of coupons redeemed by users in each
              subscription cycle.
            </li>
            <li>
              - Feedback/Complaints: Any feedback or complaints provided by
              users.
            </li>
            <li>
              - Location Information: We may collect approximate location
              information to tailor the service experience based on geographic
              region. This information is not stored in our database and is used
              solely to provide localized service features.
            </li>
          </ul>
        </p>
        <br />
        <br />
        <h2 className="text-2xl font-bold leading-10">Payment Information</h2>
        <p>
          We do not store any of your bank account or credit card information.
          All financial information that pertains to a user is strictly handled
          by our third-party payment processor, Stripe. You can review Stripe's
          privacy policy for more information on how they handle your financial
          information:{" "}
          <a
            className="underline text-blue-600"
            href="https://stripe.com/privacy"
          >
            Stripe Privacy Policy.
          </a>
        </p>
        <br />
        <br />
        <h2 className="text-2xl font-bold leading-10">
          How We Use Your Information
        </h2>
        <p>We use the information we collect in the following ways:</p>
        <br />
        <ul>
          <li>
            - <b>Service Provision:</b> To provide and maintain our service,
            including managing subscriptions and delivering coupons.
          </li>
          <li>
            - <b>Improvement:</b> To understand and analyze usage trends and
            preferences to improve and expand our service.
          </li>
          <li>
            - <b>Customer Support:</b> To respond to inquiries, complaints, and
            feedback, and provide support.
          </li>
          <li>
            - <b>Security:</b> To detect, prevent, and address technical issues
            and fraud.
          </li>
          <li>
            - <b>Communication:</b> To communicate with you directly for
            customer service, to provide you with updates and other information
            relating to the service, and for marketing and promotional purposes.
          </li>
        </ul>
        <br />
        <br />
        <h2 className="text-2xl font-bold leading-10">Data Security</h2>
        <p>
          We implement reasonable security measures to protect your personal
          information. All transactions are encrypted using secure socket layer
          (SSL) technology. Stripe also employs strict security measures to
          protect your information, as described in its Privacy Policy.
        </p>
        <br />
        <br />
        <h2 className="text-2xl font-bold leading-10">
          Sharing Your Information
        </h2>
        <p>
          We do not share your personal information with third parties except as
          necessary to provide our services, comply with the law, protect our
          rights, or if you consent to share specific information.
        </p>
        <br />
        <br />
        <h2 className="text-2xl font-bold leading-10">
          Your Data Protection Rights
        </h2>
        <p>
          Depending on your location, you may have the following rights
          regarding your personal information:
        </p>

        <br />
        <ul>
          <li>
            - The right to access – You have the right to request copies of your
            personal data.
          </li>
          <li>
            - The right to rectification – You have the right to request that we
            correct any information you believe is inaccurate.
          </li>
          <li>
            - The right to erasure – You have the right to request that we erase
            your personal data, under certain conditions.
          </li>
        </ul>

        <br />
        <br />
        <h2 className="text-2xl font-bold leading-10">Data Retention</h2>
        <p>
          We will retain your personal information only for as long as necessary
          to fulfill the purposes outlined in this Privacy Policy, unless a
          longer retention period is required or permitted by law. The specific
          retention period for each category of data is as follows:
        </p>
        <br />
        <ul>
          <li>
            - <b>Subscription Information:</b> We will retain subscription start
            date, end date, cancellation status, and user email for as long as
            you maintain an active subscription and for a reasonable period
            thereafter as needed for business purposes or legal requirements.
          </li>
          <li>
            -{" "}
            <b>
              Coupon Usage: Details of coupons used in each subscription cycle
              will be retained for a reasonable period after the subscription
              ends as needed for accurate service provision, customer support,
              business purposes, or legal requirements.
            </b>
          </li>
          <li>
            - <b>Feedback/Complaints:</b> Feedback and complaints provided by
            users will be retained for as long as necessary to address the
            issues raised and for a reasonable period thereafter for business
            purposes.
          </li>
          <li>
            - <b>Location Information:</b> Location data used for service
            localization purposes is not stored in our database and is only
            processed temporarily during your session.
          </li>
        </ul>
        <br />
        <p>
          We may use data related to coupon usage to improve our service, in
          accordance with our internal practices and without explicit disclosure
          in this Privacy Policy.
        </p>
        <br />
        <p>
          After the retention period expires, we will securely delete or
          anonymize your personal information in a manner designed to ensure it
          cannot be reconstructed or read.
        </p>
        <br />
        <br />
        <h2 className="text-2xl font-bold leading-10">
          Changes to This Privacy Policy
        </h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. We
          encourage you to review this Privacy Policy periodically for any
          changes.
        </p>
        <br />
        <br />
        <h2 className="text-2xl font-bold leading-10">
          Other legal requirements
        </h2>
        <p>
          We may disclose the information listed above in the good faith belief
          that such action is necessary to:
        </p>
        <br />
        <ul>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            Protect the personal safety of Users of the Service or the public
          </li>
          <li>Protect against legal liability</li>
        </ul>

        <br />
        <br />
        <h2 className="text-2xl font-bold leading-10">Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at:
        </p>
        <br />
        <p>Campfire x MoCoShow Support</p>
        <p>Email: campfireloyalty@gmail.com</p>
        <p>US Phone Number: +1 (248) 660-9169</p>
        <br />
      </div>

      <Footer />
    </div>
  );
};

export default MoCoPassPrivacy;
