import React, { useState } from "react";
import useWindowDimensions from "../hook/useWindowDimensions";
import { FaFire } from "react-icons/fa";
import LoadingIcon from "./LoadingIcon";
import { RxCross2 } from "react-icons/rx";
import { RxCheck } from "react-icons/rx";

const Waitlist = ({ waitlistRef }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [emailValidated, setEmailValidated] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sentSuccessful, setSentSuccessful] = useState(null);

  function formIsValid() {
    return (emailValidated || email.length == 0) && name.length > 0;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    fetch(process.env.REACT_APP_WAIT_LIST_EMAIL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        customer_type: isChecked ? "business" : "customer",
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          console.log(await response.json());
          setSentSuccessful(false);
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setSentSuccessful(true);
        console.log("Response is ", data);
      })
      .catch((error) => {
        setSentSuccessful(false);
        console.error("There was a problem with the fetch operation:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value);
    setEmailValidated(isValid);
  };

  const handleNameChange = (event) => {
    const { value } = event.target;
    setName(value);
  };

  return (
    <div ref={waitlistRef} className="w-[100%] px-[10vw] py-[7em] h-fit ">
      <div className="mb-16 justify-center items-center h-[20%] text-center">
        <h2 className="text-5xl font-bold ">Join the Waitlist</h2>
      </div>

      <div className="waitlist-container">
        <div className="waitlist-textinput justify-center items-center flex flex-col">
          <h2 className="text-lg  sm:text-xl xl:text-2xl font-bold text-center mb-[1em] p-[1em]  h-fit w-fit">
            We are currently only available in Ypsilanti, MI and Montgomery
            County, Maryland. Join the waitlist if you would like us to be in
            your area!
          </h2>
          <form
            className="flex justify-center items-center flex-col w-fit p-[1em] px-[2em]"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col">
              <input
                type="name"
                onChange={handleNameChange}
                className="shadow-2xl rounded m-1  text-lg  p-[0.5em] focus:outline-none border-2 border-solid "
                placeholder="Name"
                required
              ></input>
              <input
                type="email"
                onChange={handleEmailChange}
                className="shadow-2xl rounded m-1  text-lg  p-[0.5em] focus:outline-none border-2 border-solid "
                placeholder="Email (optional)"
              ></input>

              {/* <label className="shadow-2xl radio-label ml-[0.5em]">
                <input
                  type="radio"
                  value="consumer"
                  name="type"
                  checked={selectedOption === "consumer"}
                  onChange={() => {
                    setSelectedOption("consumer");
                  }}
                  required
                />
                <span className="shadow-2xl radio-custom"></span>I am a Consumer
              </label> */}
              <label className="max-w-[300px] radio-label ml-[0.5em] ">
                <input
                  type="checkbox"
                  value="business"
                  name="type"
                  noValidate
                  checked={isChecked}
                  onChange={(event) => {
                    setIsChecked(event.target.checked);
                  }}
                />
                <span className="shadow-2xl radio-custom"></span>I am a business
                owner and I would like to join the program
              </label>
            </div>
            <div className="relative">
              <input
                type="submit"
                value={
                  isLoading || sentSuccessful != null ? "           " : "SUBMIT"
                }
                disabled={!formIsValid() || sentSuccessful != null}
                className={`${
                  formIsValid() || sentSuccessful != null
                    ? "cursor-pointer opacity-100"
                    : "opacity-50"
                }  m-1  transition rounded text-lg sm:text-xl p-[0.5em] focus:outline-none border-2 border-solid bg-[#FF914D]`}
              ></input>
              {isLoading && (
                <div className="absolute inset-0 m-auto">
                  <LoadingIcon></LoadingIcon>
                </div>
              )}
              {sentSuccessful == false && (
                <div className="absolute inset-0 m-auto justify-center items-center flex flex-col">
                  <RxCross2 size={64} color="red"></RxCross2>
                </div>
              )}
              {sentSuccessful && (
                <div className="absolute inset-0 m-auto justify-center items-center flex">
                  <RxCheck size={64} color="#03fc20"></RxCheck>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Waitlist;
