import React, { useRef } from "react";
import NavBar from "../components/NavBar";
import SignUpForm from "../components/SignUpForm";

const Support = () => {
  const signUpRef = useRef(null);
  return (
    <div className="min-h-screen">
      <NavBar signUpRef={signUpRef}></NavBar>
      <div className="m-2">
        <h2 className="text-4xl font-bold">FAQs</h2>
        <h3 className="text-3xl underline">
          1. Is any personal data collected?
        </h3>
        <h4>
          No personal data is collected except user's email address and
          passwords for login purposes.
        </h4>
        <h3 className="text-3xl underline">
          2. How is my password stored by Campfire?
        </h3>
        <h4>
          We use a one-way hashing algorithm to hash your password before
          storing it in the database, this hashed password is not human readable
          and will take an impractical amount of time to hack. Even if an
          attacker gain access to the database, they would not be able to unhash
          your password.
        </h4>
        <h3 className="text-3xl underline">
          3. Can I have my account deleted?
        </h3>
        <h4>
          Yes, please send us an email using the email that you signed up for
          Campfire with the email title "Account Deletion Request". We will
          delete your account within 48 hours
        </h4>

        <br></br>
        <h2 className="text-4xl font-bold">Company's Contact Information</h2>
        <h4 className="text-2xl">Email Address: campfireloyalty@gmail.com</h4>
        <h4 className="text-2xl">Phone number: (248) 660-9169</h4>
        <br></br>
        <h2 className="text-4xl font-bold">Developer's Contact Information</h2>
        <h4 className="text-2xl">Name: Kah Huan (Bernard) Yap</h4>
        <h4 className="text-2xl">Email: mameehy@hotmail.com</h4>
        <br></br>
      </div>
      <SignUpForm signUpRef={signUpRef}></SignUpForm>
    </div>
  );
};

export default Support;
