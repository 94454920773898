import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
//

// Initialize Firebase
const CONSUMER_FIREBASE_APP = firebase.initializeApp(firebaseConfig);

const firebaseConfigBusiness = {
  apiKey: process.env.REACT_APP_FIREBASE_BUSINESS_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_BUSINESS_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_BUSINESS_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_BUSINESS_STORAGE_BUCKET,
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_BUSINESS_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_BUSINESS_APP_ID,
};

const BUSINESS_FIREBASE_APP = firebase.initializeApp(
  firebaseConfigBusiness,
  "business"
);
const firebaseConfigHermes = {
  apiKey: process.env.REACT_APP_FIREBASE_HERMES_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_HERMES_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_HERMES_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_HERMES_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_HERMES_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_HERMES_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_HERMES_MEASUREMENT_ID,
};

const HERMES_FIREBASE_APP = firebase.initializeApp(
  firebaseConfigHermes,
  "hermes"
);

export const FIREBASE_AUTH = firebase.auth(CONSUMER_FIREBASE_APP);
export const FIREBASE_AUTH_BUSINESS = firebase.auth(BUSINESS_FIREBASE_APP);
export const FIREBASE_AUTH_HERMES = firebase.auth(HERMES_FIREBASE_APP);
