import React from "react";
import NavBarMoco from "../components/NavBarMoco";
import Footer from "../components/Footer";

const MoCoPassTerms = () => {
  return (
    <div
      className="bg-[#feffff] "
      style={{ overflowX: "hidden", overflowY: "auto" }}
    >
      <NavBarMoco />
      <div className="p-8 mt-[7.5vh] sm:mt-[10vh] md:mt-[12.5vh]  w-full justify-center items-center">
        <h2 className="text-4xl font-bold leading-10">
          Terms of Use for MoCoPass
        </h2>
        <br />
        <h2 className="text-2xl font-bold leading-10">Introduction</h2>
        <p>
          Welcome to Campfire x MoCoShow! By purchasing and using MoCoPass, you
          agree to the following terms and conditions. Please read them
          carefully, as these terms govern your use of the subscription service,
          which provides access to various coupons and offers uploaded by
          businesses in Montgomery and neighboring counties, in Maryland. Please
          read these Terms carefully.
        </p>
        <br />
        <h2 className="text-2xl font-bold leading-10">Acceptance of Terms</h2>
        <p>
          By purchasing MoCoPass, you agree to be bound by these Terms of Use.
          If you do not agree with any part of these terms, you should not
          purchase or use MoCoPass.
        </p>
        <br />
        <h2 className="text-2xl font-bold leading-10">Subscription and Fees</h2>
        <p>
          The subscription fee is $4.99 per month. This fee grants you access to
          all coupons available within the app.
        </p>
        <p>
          We reserve the right to change the subscription fee at any time. Any
          fee changes will be shown on the app and may be communicated to you in
          advance.
        </p>
        <br />
        <h2 className="text-2xl font-bold leading-10">Use of Coupons</h2>
        <ul>
          <li>
            - Coupons are uploaded by local businesses, MoCoShow, or Campfire
            and can be uploaded or removed at any time without prior notice.
          </li>
          <li>- You can use each coupon once per billing cycle.</li>
          <li>
            - Coupons cannot be combined with other coupons or offers. For
            example, you cannot apply multiple coupons at a business at once.
          </li>
          <li>
            - To use a coupon, you must manually click on the "Redeem" button
            (after clicking on the coupon you want to use) and present the
            coupon to the cashier for verification. As of right now, no online
            discounts are possible.
          </li>
        </ul>
        <br />
        <h2 className="text-2xl font-bold leading-10">
          MoCoPass Purchase and Usage
        </h2>
        <ul>
          <li>
            - <b>Eligibility:</b> You must be at least 18 years old to purchase
            and use MoCoPass.
          </li>
          <li>
            - <b>Purchase:</b> MoCoPass can be purchased through the Campfire
            app via in-app purchase.
          </li>
          <li>
            - <b>Usage:</b> MoCoPass grants you access to various coupons. Each
            coupon can be redeemed at the respective business once per
            subscription cycle.
          </li>
          <li>
            - <b>Refresh:</b> Coupons provided through MoCoPass are refreshed
            every 30 days and 1 hour after your subscription, starting on the
            day of purchase. “Refreshing” means the coupons you redeemed before
            can be redeemed again.
          </li>
        </ul>
        <br />
        <h2 className="text-2xl font-bold leading-10">Coupon Availability</h2>
        <p>
          We do not guarantee the availability or number of coupons available on
          the service at any given time. Coupons are uploaded by Montgomery and
          neighboring county businesses and may vary in terms of value. We
          strive to provide a variety of offers, but we cannot guarantee
          continuous availability or specific quantities of coupons.
        </p>
        <br />
        <h2 className="text-2xl font-bold leading-10">Monthly Subscription</h2>
        <p>
          By purchasing a Monthly Subscription, you agree to an initial and
          recurring Monthly Subscription fee at the then-current Monthly
          Subscription rate, and you accept responsibility for all recurring
          charges until you cancel your subscription. You may cancel your
          Monthly Subscription at any time, subject to the terms of our
          cancellation policy.
        </p>
        <br />
        <h2 className="text-2xl font-bold leading-10">
          AUTOMATIC MONTHLY RENEWAL TERMS
        </h2>
        <p>
          Once you subscribe, Campfire will automatically process your Monthly
          Subscription fee in the next billing cycle (30 days and 1 hour after
          the subscription date). Campfire will continue to automatically
          process your Monthly Subscription fee each month at the then-current
          Monthly Subscription rate until you cancel your subscription. You will
          only be charged if you have a default payment method on file. If there
          is no default payment method attached, your subscription will be
          canceled automatically.
        </p>
        <p>
          Additionally, if there are issues with your credit card and a payment
          cannot be processed at month-end, your subscription will also be
          canceled.
        </p>
        <br />
        <h2 className="text-2xl font-bold leading-10">Cancellation Policy</h2>
        <ul>
          <li>
            - Your subscription will automatically renew every 30 days and 1
            hour unless you cancel it before the renewal date.
          </li>
          <li>
            - You can cancel your subscription at any time by going to the
            MoCoPass tab (the 3rd icon at the bottom), clicking on the gear icon
            on the top right, then clicking on Cancel Subscription at the
            bottom.
          </li>
          <li>
            - If you cancel, you will continue to have access to the coupons
            until the end of your current billing cycle.
          </li>
          <li>
            - No refunds will be provided for partial months of the
            subscription.
          </li>
          <li>
            - Upon cancellation, you will not be billed for the next billing
            cycle.
          </li>
        </ul>
        <br />
        <h2 className="text-2xl font-bold leading-10">Refunds</h2>
        <p>
          All sales of MoCoPass are final. We do not offer refunds or exchanges
          for MoCoPass purchases.
        </p>
        <br />
        <h2 className="text-2xl font-bold leading-10">Changes to Terms</h2>
        <p>
          We reserve the right to modify these Terms of Use at any time. Any
          changes will be effective immediately upon posting. Your continued use
          of MoCoPass after any changes constitutes your acceptance of the new
          terms.
        </p>
        <br />
        <h2 className="text-2xl font-bold leading-10">Privacy</h2>
        <p>
          Your use of MoCoPass is also governed by our Privacy Policy. We do not
          store any of your bank account or credit card information. All
          financial information related to your purchase is handled by Stripe,
          our payment processor.
        </p>
        <br />
        <h2 className="text-2xl font-bold leading-10">
          Limitation of Liability
        </h2>
        <p>
          The service is provided "as is" and "as available" without any
          warranties of any kind, either express or implied. To the fullest
          extent permitted by law, Campfire shall not be liable for any
          indirect, incidental, special, consequential, or punitive damages, or
          any loss of profits or revenues, whether incurred directly or
          indirectly, or any loss of data, use, goodwill, or other intangible
          losses, resulting from:
        </p>
        <ul>
          <li>- Your use or inability to use MoCoPass.</li>
          <li>
            - Any unauthorized access to or use of our servers and/or any
            personal information stored therein.
          </li>
        </ul>
        <br />
        <h2 className="text-2xl font-bold leading-10">Governing Law</h2>
        <p>
          These Terms of Use shall be governed by and construed in accordance
          with the laws of the state of Maryland.
        </p>
        <br />
        <h2 className="text-2xl font-bold leading-10">Restrictions on Use</h2>
        <p>When using our service, you agree not to:</p>
        <ul>
          <li>
            1. Misuse the Service: Engage in any activity that could harm or
            interfere with the normal operation of the service, including but
            not limited to hacking, introducing viruses, or spamming.
          </li>
          <li>
            2. Unlawful Activities: Use the service for any illegal or
            unauthorized purpose, or engage in any activity that violates local,
            state, national, or international laws and regulations.
          </li>
          <li>
            3. Content Misuse: Copy, distribute, reproduce, modify, create
            derivative works from, publicly display, or otherwise exploit any
            content available through the service without our prior written
            consent or as expressly permitted by these Terms.
          </li>
          <li>
            4. Impersonation: Impersonate any person or entity, or falsely state
            or otherwise misrepresent your affiliation with a person or entity.
          </li>
          <li>
            5. Automated Use: Use any automated means, including robots,
            spiders, or data mining techniques, to access or use the service.
          </li>
          <li>
            6. Resale or Redistribution: Resell, sublicense, or redistribute any
            part of the service without our prior written consent.
          </li>
          <li>
            7. Interference with Others: Interfere with or disrupt the access,
            use, or enjoyment of the service by others, including any other
            users or our personnel.
          </li>
          <li>
            8. Unauthorized Access: Attempt to gain unauthorized access to any
            part of the service, other user accounts, computer systems, or
            networks connected to the service.
          </li>
          <li>
            9.Commercial Use: Use the service for any commercial purposes
            without our prior written consent, other than the intended purpose
            of utilizing coupons at participating businesses.
          </li>
        </ul>
        <br />
        <h2 className="text-2xl font-bold leading-10">Corrections</h2>
        <p>
          There may be information on the Services that contains typographical
          errors, inaccuracies, or omissions, including descriptions, pricing,
          availability, and various other information.
        </p>
        <p>
          We reserve the right to correct any errors, inaccuracies, or omissions
          and to change or update the information on the Services at any time,
          without prior notice.
        </p>
        <br />
        <h2 className="text-2xl font-bold leading-10">Contact Us</h2>
        <p>
          If you have any questions about these Terms of Use, please contact us
          at:
        </p>
        <br />
        <p>Campfire x MoCoShow Support</p>
        <p>Email: campfireloyalty@gmail.com</p>
        <p>US Phone Number: +1248 (660) 9169</p>
      </div>
      <Footer />
    </div>
  );
};

export default MoCoPassTerms;
