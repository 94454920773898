import React from "react";
import { RxCross2 } from "react-icons/rx";
import "./Modal.css";

const Modal = ({ show, onClose, children }) => {
  if (!show) {
    return null;
  }
  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          <RxCross2 size={30} color={"red"} />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
