import React, { useContext, useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FIREBASE_AUTH_HERMES } from "../../FirebaseConfig";
import { Navigate, useNavigate } from "react-router-dom";
import { setPersistence } from "firebase/auth";
import { UserContext } from "../../context/UserProvider";
import { isAdmin } from "../../helpers/helper";

const HermesLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const { setUser, isValidUser, user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Hermes Login";
  }, []);
  async function handleOnSubmit(event) {
    event.preventDefault();

    if (!password || !email) return;

    try {
      const response = await FIREBASE_AUTH_HERMES.signInWithEmailAndPassword(
        email,
        password
      );

      if (response.user) {
        setUser(response.user);
        navigate("/hermes/dashboard");
      }
    } catch (err) {
      if (err.code == "auth/invalid-credential") {
        alert("Credential is wrong");
      } else if (err.code == "auth/invalid-email") {
        alert("Email is invalid");
      } else {
        alert("Something went wrong");
      }
      console.error(err);
    }
  }

  const navigateIfValid = () => {
    if (isValidUser) {
      return <Navigate to="/hermes" />;
    }

    return null;
  };
  return (
    <>
      {navigateIfValid()}
      <div class="flex justify-center items-center bg-[#FFF5E1] h-screen">
        <div class="bg-white shadow-xl rounded-lg	w-[35vw] p-[1em] flex flex-col justify-center items-center">
          <div class="pb-[1em] border-b-2 w-full">
            <h2 class="text-4xl">Login to Hermes</h2>
          </div>
          <form
            onSubmit={handleOnSubmit}
            class="pt-[1em] flex flex-col  focus:ring-0 w-full items-center justify-center"
          >
            <input
              class="text-xl my-2 p-2 border-[1px] outline-none w-[50%] flex-1"
              type="text"
              placeholder="Email"
              required
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            ></input>
            <div class="relative my-2  w-[50%] border-[1px]">
              <input
                class="flex flex-1 text-xl p-2 outline-none w-full  pr-[40px]  focus:ring-0  justify-start"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                required
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              ></input>
              <button
                type="button"
                onClick={() => {
                  setShowPassword((val) => !val);
                }}
                className="absolute inset-y-0 right-0 px-3 flex items-center"
              >
                {showPassword ? (
                  <FaEyeSlash size={20}></FaEyeSlash>
                ) : (
                  <FaEye size={20}></FaEye>
                )}
              </button>
            </div>
            <button
              className="mt-[1em] rounded-b-xl rounded-t-md shadow-2xl border-r-[2px] border-r-[#FF840D]	 text-2xl py-[0.5em] bg-[#FFF5E1]  w-fit px-[1em] border-b-[4px] border-b-[#FF840D]  hover:bg-[#FFDBB1] transition  "
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default HermesLogin;
